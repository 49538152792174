import React, { useRef, useState } from 'react';
import {
  Col,
  Card,
  Table,
  Button,
  Tag,
  Skeleton,
  Checkbox,
  Popover,
} from 'antd';
import PopoverText from 'helpers/PopOver';
import {
  SnippetsOutlined,
  CloseCircleFilled,
  CloseOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { Bounce } from 'react-awesome-reveal';
import ErrorCard from '../../../../../components/UI/ErrorCard/ErrorCard';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  SelectCandidates,
  UnSelectCandidates,
  ClearSelectCandidates,
  GetCandidateTags,
  ClearSelectTags,
} from '../../../../../store/candidates/actions';
import { Candidates } from 'store/candidates/types';
import { randomColor } from '../../../../../helpers/randomColor';
import CandidateLists from '../CandidateLists';
import { useUntagCandidateMutation } from '../../../../../graphql/generated/graphql';

interface Props {
  loading: any;
  data: any;
  error: any;
  url: string;
  totalCount: number;
  pageOptions: any;
  setPageOptions: any;
  searchOptions: any;
  showAdvanceFilters: any;
  advanceFilters: any;
  confirmUnTagCandidate: any;
  setPagination: any;
  setSorting: any;
}

interface Users {
  key: number;
  id: string;
  photo: string;
  fname: string;
  lname: string;
  link: JSX.Element;
  title: string;
  position: string;
  email: string;
  summary: string;
  location: string;
  status: string;
  jobCount: number;
  companyCount: number;
  createdAt: string;
}

const WebCandidates: React.FC<Props> = ({
  loading,
  data,
  error,
  url,
  totalCount,
  pageOptions,
  setPageOptions,
  setPagination,
  setSorting,
  searchOptions,
  showAdvanceFilters,
  advanceFilters,
  confirmUnTagCandidate,
}) => {
  let displayContent = null;
  const dispatch = useDispatch();

  const [allSelected, setAllSelected] = useState(false);

  const selectedCandidates = useSelector((state: any) => state?.candidates);

  React.useEffect(() => {
    dispatch(ClearSelectCandidates([]));
    dispatch(ClearSelectTags([]));
  }, []);

  React.useEffect(() => {
    dispatch(ClearSelectCandidates([]));
    dispatch(ClearSelectTags([]));
    return;
  }, []);

  React.useEffect(() => {
    if (selectedCandidates?.candidates.length == 0) setAllSelected(false);
  }, [selectedCandidates]);

  const resumeHandler = (resume: any, status: any) => {
    if (resume) {
      return (
        <Button
          className="ant-btn-teal"
          shape="circle"
          onClick={() =>
            window.open('https://docs.google.com/gview?url=' + resume, '_blank')
          }
        >
          <SnippetsOutlined />
        </Button>
      );
    } else {
      return <CloseCircleFilled className="text-light font-30" />;
    }
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field === 'link') {
      sorter.field = 'title';
    }
    if (sorter.field === 'firstNameLink') {
      sorter.field = 'firstName';
    }
    if (sorter.field === 'lastNameLink') {
      sorter.field = 'lastName';
    }
    if (sorter.field === 'emailLink') {
      sorter.field = 'email';
    }
    setPagination({
      ...pagination,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
    setSorting({
      column: sorter.field,
      order: sorter.order,
    });
  };

  const sourceHandler = (source: any, status: any) => {
    return <Tag color="blue">{source}</Tag>;
  };

  const onSelectCandidate = (e: CheckboxChangeEvent) => {
    const val = e.target.value;
    if (e.target.checked) {
      dispatch(SelectCandidates(val._id));
      // if (val.tags) {
      //   dispatch(GetCandidateTags(val.tags));
      // }
    } else {
      dispatch(UnSelectCandidates(val._id));
    }
  };

  const onSelectAllCandidate = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setAllSelected(true);
      data.getAllCandidatesInBO.forEach((candidate: any) => {
        dispatch(SelectCandidates(candidate._id));
      });
    } else {
      setAllSelected(false);
      dispatch(ClearSelectCandidates([]));
    }
  };

  const columns: ColumnsType<Users> = [
    {
      title: (
        <Checkbox
          checked={allSelected}
          className="top-checkbox"
          onChange={(e: CheckboxChangeEvent) => onSelectAllCandidate(e)}
        />
      ),
      dataIndex: 'checkbox',
      key: 'checkbox',
      fixed: 'left',
      width: 50,
    },
    {
      title: 'First name',
      dataIndex: 'firstNameLink',
      key: 'firstNameLink',
      // fixed: 'left',
      width: 150,
      showSorterTooltip: false,
      sorter: true,
      // sorter: (a: any, b: any) => a.firstName?.localeCompare(b?.firstName),
      sortDirections: ['ascend', 'descend', 'ascend'],
      // defaultSortOrder: 'ascend',
    },
    {
      title: 'Last name',
      dataIndex: 'lastNameLink',
      key: 'lastNameLink',
      // fixed: 'left',
      width: 150,
      showSorterTooltip: false,
      // sorter: (a: any, b: any) => a?.lastName?.localeCompare(b?.lastName),
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      // defaultSortOrder: 'ascend',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 120,
      showSorterTooltip: false,
      // sorter: true,
      // sorter: (a: any, b: any) => a.title?.localeCompare(b?.title),
      // sortDirections: ['ascend', 'descend', 'ascend'],
      // defaultSortOrder: 'ascend',
      // not sorting bcz if candidate has no title we are showing his postion
      render: (title, row) => {
        return (
          <PopoverText country={row.title ?? row.position} isTag={false} />
        );
      },
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      width: 200,
      showSorterTooltip: false,
      // sorter: (a: any, b: any) => a.company?.localeCompare(b?.company),
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      // defaultSortOrder: 'ascend',
    },
    {
      title: 'Email',
      dataIndex: 'emailLink',
      key: 'emailLink',
      width: 250,
      showSorterTooltip: false,
      // sorter: (a: any, b: any) => a.email?.localeCompare(b?.email),
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      // defaultSortOrder: 'ascend',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: 100,
    },
    // {
    //   title: 'Profile status',
    //   dataIndex: 'status',
    //   key: 'status',
    //   width: 120,
    //   render: (status) => (
    //     <>
    //       {
    //         <span
    //           className={`font-10 px-2 m-2 badge badge-${status} badge-teal text-white`}
    //           key={status}
    //         >
    //           {status}
    //         </span>
    //       }
    //     </>
    //   ),
    // },
    {
      title: 'Resume',
      dataIndex: 'resume',
      key: 'resume',
      width: 90,
      render: (resume, row) => resumeHandler(resume, row.status),
    },
    // {
    //   title: 'Source',
    //   dataIndex: 'source',
    //   key: 'source',
    //   render: (source, row) => sourceHandler(source, row.status),
    // },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend',
      key: 'createdAt',
      width: 150,
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      width: 270,
    },
  ];

  const Tags = (candidateId: any, tags: any[]) => {
    const AllTags = (tags: any[]) => {
      return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {tags.map((tag, index) => {
            const color: any = '#1e97a2';
            // var color: any = randomColor()
            return (
              <Tag
                style={{ borderRadius: 20, marginTop: 5 }}
                color={tag.color}
                key={index}
              >
                {tag.name}
                <CloseOutlined
                  style={{ marginLeft: 5 }}
                  onClick={() =>
                    confirmUnTagCandidate({
                      candidateId: candidateId,
                      tagId: tag._id,
                      tagName: tag.name,
                    })
                  }
                />
              </Tag>
            );
          })}
        </div>
      );
    };

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {tags.map((tag, index) => {
          const color: any = '#1e97a2';
          // var color: any = randomColor()
          return (
            index < 3 && (
              <Tag
                style={{ borderRadius: 20, marginTop: 5 }}
                color={tag.color}
                key={index}
              >
                {tag.name}
                <CloseOutlined
                  style={{ marginLeft: 5 }}
                  onClick={() =>
                    confirmUnTagCandidate({
                      candidateId: candidateId,
                      tagId: tag._id,
                      tagName: tag.name,
                    })
                  }
                />
              </Tag>
            )
          );
        })}
        {tags.length > 3 && (
          <Popover
            placement="leftBottom"
            content={() => AllTags(tags)}
            title={<span style={{ fontSize: '16px' }}>Tags</span>}
            trigger="click"
          >
            <Tag
              style={{
                color: '#012653',
                borderColor: '#012653',
                borderRadius: 20,
                marginTop: 5,
                cursor: 'pointer',
                backgroundColor: 'transparent',
              }}
            >
              See more
            </Tag>
          </Popover>
        )}
      </div>
    );
  };

  const generateActiveCandidatesTableData = () => {
    if (data != null) {
      const candidateslistData: any = data?.getAllCandidatesInBO;
      // console.log(candidateslistData.length);
      const candidateIDs = candidateslistData.map((candidate: any) => {
        return candidate._id;
      });
      const candidatesData = candidateslistData.map((candidate: any) => {
        // console.log('url', url, 'candidate._id', candidate._id);

        return {
          key: candidate._id,
          id: candidate._id,
          firstName: candidate.firstName,
          lastName: candidate.lastName,
          checkbox: (
            <Checkbox
              checked={selectedCandidates?.candidates.includes(candidate._id)}
              value={candidate}
              className="row-checkbox"
              onChange={onSelectCandidate}
            />
          ),
          firstNameLink: (
            <Link
              to={{
                pathname: `${url}/${candidate._id}`,
                state: {
                  candidateIDs: candidateIDs,
                  candidateId: candidate._id,
                  fromURL: `${url}`,
                },
              }}
            >
              {candidate.firstName}
            </Link>
          ),
          lastNameLink: (
            <Link
              to={{
                pathname: `${url}/${candidate._id}`,
                state: {
                  candidateIDs: candidateIDs,
                  candidateId: candidate._id,
                  fromURL: `${url}`,
                },
              }}
            >
              {candidate.lastName}
            </Link>
          ),
          title: candidate.title,
          position: candidate.position,
          company: candidate.company,
          resume: candidate.resume,
          // status: candidate.isProfileCompleted ? 'complete' : 'incomplete',
          email: candidate?.email,
          emailLink: (
            <Link
              to={{
                pathname: `${url}/${candidate._id}`,
                state: {
                  candidateIDs: candidateIDs,
                  candidateId: candidate._id,
                  fromURL: `${url}`,
                },
              }}
            >
              {candidate?.email}
            </Link>
          ),
          source: candidate?.source ? candidate?.userSource : candidate?.source,
          location: candidate?.location ?? '',
          createdAt: moment(parseInt(candidate.createdAt)).format(
            'DD/MM/YY'
            // 'MMMM DD,  YYYY'
          ),
          tags: candidate?.tags ? Tags(candidate?._id, candidate?.tags) : '',
        };
      });
      return candidatesData;
    }
  };

  const onChange = (current: number, pageSize: number) => {
    setPageOptions({
      page: current,
      pageSize: pageSize,
    });
  };

  // displayContent = (
  //   <Col span={24}>
  //     <Bounce>
  //       <Card>
  //         <Skeleton active paragraph={{ rows: 7 }} />
  //       </Card>
  //     </Bounce>
  //   </Col>
  // );

  if (!loading && error) {
    displayContent = (
      <Col span={12}>
        <ErrorCard />
      </Col>
    );
  }
  // if (!loading && data) {
  const activeCandidatesData: any = generateActiveCandidatesTableData() || [];

  displayContent = (
    <span className="preselected-candidates-table-parent">
      <Table
        className={
          activeCandidatesData.length > 0
            ? 'table-striped'
            : 'table-striped empty-table'
        }
        columns={columns}
        sortDirections={['ascend', 'descend', 'ascend']}
        dataSource={activeCandidatesData}
        loading={loading}
        size="small"
        locale={{ emptyText: 'No candidate found' }}
        pagination={{
          total: totalCount,
          current: pageOptions.page,
          // hideOnSinglePage: true,
          pageSize: pageOptions.pageSize,
          pageSizeOptions: [10, 15, 20, 50, 100],
          onChange: onChange,
        }}
        scroll={{ x: 1300 }}
        onChange={handleTableChange}
      />
    </span>
  );
  // }

  return <>{displayContent}</>;
};

export default WebCandidates;
