import React, { useContext, useState } from 'react';
import { Avatar, Button, Popover } from 'antd';
import { EditDelete } from '../../../Shared';
import { EditContext } from '../../../Context/EditContext';
import ExperienceEditDrawer from '../../../Edit/ExperienceEditDrawer';

interface PropsType {
  index: any;
  experience: any;
  candidateId: string;
  onEdit: (id: any, experience: any) => void;
}

const Styles = {
  root: {
    borderLeft: 'solid 1.5px #2E4A79',
    paddingLeft: 25,
    display: 'inline-block',
    maxWidth: 400,
    flex: '85%',
  },
  wrapper: {
    display: 'flex',
    // alignItems: 'end',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  year: {
    fontStyle: 'italic',
    fontSize: 14,
    fontWeight: 400,
    marginTop: 0,
    marginBottom: 0,
    color: '#495057',
  },
  position: {
    fontWeight: 600,
    fontSize: 16,
    marginTop: 0,
    marginBottom: 0,
    color: '#000000d9',
  },
  company: {
    fontWeight: 600,
    fontSize: 14,
    marginTop: 0,
    marginBottom: 0,
    color: '#000000d9',
  },
  avatar: {
    backgroundColor: '#F5F7F9',
  },
  actionWrapper: {
    display: 'flex',
    // flexDirection:'column'
  },
};

const SingleExperience: React.FC<PropsType> = ({
  index,
  experience,
  candidateId,
  onEdit,
}) => {
  const editContext: any = useContext(EditContext);
  const [open, setOpen] = useState(false);
  const { setData, setUpdatedData } = editContext;
  const [openDelete, setOpenDelete] = useState(false);
  const { edit } = editContext;

  const handleDelete = () => {
    setOpenDelete(false);
    setUpdatedData({
      ...editContext?.updatedData,
      experiences: editContext?.candidate.experiences.filter(
        (exp: any, ind: any) => ind != index
      ),
    });
    setData({
      ...editContext?.candidate,
      experiences: editContext?.candidate.experiences.filter(
        (exp: any, ind: any) => ind != index
      ),
    });
  };

  return (
    <>
      <ExperienceEditDrawer
        onUpdate={() => setOpen(false)}
        open={open}
        candidate={experience}
        candidateID={candidateId}
        handleClose={() => setOpen(false)}
        data={experience}
        index={index}
      />
      <div style={Styles.wrapper}>
        <div style={{ ...Styles.root, wordWrap: 'break-word' }}>
          <p style={Styles.year}>
            {experience?.startYear}-
            {experience?.current ? 'Current' : experience?.endYear}
          </p>
          <p style={Styles.position}>{experience?.position}</p>
          <p style={Styles.company}>
            {experience?.company && '@' + experience?.company}
            <span style={{ fontSize: 14, fontWeight: 400 }}>
              {experience?.location?.city && experience?.location?.country
                ? '| ' +
                  experience?.location?.city +
                  ', ' +
                  experience?.location?.country
                : experience?.location?.city &&
                  experience?.location?.country == ''
                ? '| ' + experience?.location?.city
                : ''}
            </span>
            {/* {experience?.location != null && (
              <span>
                |{' '}
                <span style={{ fontSize: 14, fontWeight: 300 }}>
                  {experience?.location}
                </span>
              </span>
            )} */}
          </p>
        </div>
        <div
          style={{
            ...Styles.actionWrapper,
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          {edit && (
            <Popover
              content={
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button onClick={() => setOpenDelete(false)}>No</Button>
                  <Button type={'primary'} onClick={handleDelete}>
                    Yes
                  </Button>
                </div>
              }
              title={
                <h4 style={{ margin: 0, textAlign: 'center' }}>
                  Are you sure?
                </h4>
              }
              trigger="click"
              open={openDelete}
              onOpenChange={() => setOpenDelete(true)}
            >
              <EditDelete
                editHandler={() => setOpen(true)}
                deleteHandler={() => setOpenDelete(true)}
              />
            </Popover>
          )}
          <div style={{ flex: 1 }}></div>
          <Avatar
            style={{
              ...Styles.avatar,
              backgroundImage: `url(${experience?.logo})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              objectFit: 'cover',
            }}
            size={32}
          ></Avatar>
        </div>
      </div>
    </>
  );
};

export default SingleExperience;
