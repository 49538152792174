import React, { useContext, useState } from 'react';
import {
  Row,
  Col,
  Tabs,
  Card,
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Popover,
  notification,
  Tooltip,
  Tag,
} from 'antd';
import Icon, {
  DeleteOutlined,
  CheckCircleOutlined,
  TagFilled,
} from '@ant-design/icons';
import {
  useGetTagsQuery,
  useCreateTagMutation,
  useTagCrawliesMutation,
  useGetTagsLazyQuery,
} from '../../../../graphql/generated/graphql';
import { useSelector, useDispatch } from 'react-redux';
import { Candidates } from 'store/candidates/types';
import {
  ClearSelectTags,
  ClearSelectCandidates,
} from 'store/candidates/actions';
import styled from 'styled-components';
import { CrawliesContext } from '../Context/crawliesContext';
import './TagsWidget.scss';

const { Option } = Select;

interface Props {
  refreshCandidates: any;
  data: any;
  isEnable: boolean;
}

interface CustomTagProps {
  label: any;
  value: any;
  closable: any;
  onClose: any;
}

const TagsWidget: React.FC<Props> = ({ refreshCandidates, data, isEnable }) => {
  const [tags, setTags] = React.useState<any[]>([]);
  const [newTags, setNewTags] = React.useState<any[]>([]);
  const [existingTags, setExistingTags] = React.useState<any[]>([]);
  const [allTags, setAllTags] = React.useState<any[]>([]);
  const [candidates, setCandidates] = React.useState<any>();

  const dispatch = useDispatch();
  const selectedCandidates = useSelector((state: any) => state?.candidates);
  const selectedCandidateTags = useSelector(
    (state: any) => state?.candidateTags
  );

  const {
    crawliesTags,
    setCrawliesTags,
    selectedCrawlies,
    setSelectedCrawlies,
    setCandidatesListing,
    candidatesListing,
  } = useContext(CrawliesContext);

  const handleChange = (value: string) => {
    const newT = Object.values(value).filter(
      (tag: string) => !allTags.includes(tag)
    );

    setNewTags(newT);
    setExistingTags(
      Object.values(value).filter((tag: string) => allTags.includes(tag))
    );

    console.log('Object', Object.values(value));
  };

  const [createTagMutation] = useCreateTagMutation();
  const [tagCrawliesMutation] = useTagCrawliesMutation();

  const showNotification = (message: string) => {
    const args = {
      message: message,
      duration: 10,
      icon: <CheckCircleOutlined style={{ color: 'green' }} />,
    };
    notification.open(args);
  };

  const clearTagsAndCandidates = () => {
    dispatch(ClearSelectTags([]));
    dispatch(ClearSelectCandidates([]));
  };

  const handleSaveTags = async () => {
    let tags: any = [];
    console.log('new tags', newTags);
    // console.log('existingTags', existingTags);

    console.log('selectedCrawlies', selectedCrawlies);

    if (newTags.length > 0) {
      await createTagMutation({
        variables: {
          name: newTags,
        },
      }).then(({ data }) => {
        const newOne: any = data?.createTag?.map((tag: any) => {
          return tag._id;
        });
        tags = [...existingTags, ...newOne];
      });
    } else {
      tags = [...existingTags];
    }
    console.log('tags', tags);
    console.log('candidatesListing', candidatesListing);

    setCrawliesTags(tags);

    tagCrawliesMutation({
      variables: {
        candidateIds: selectedCrawlies,
        tags: tags,
      },
    }).then((data: any) => {
      console.log('tags data', data);
      showNotification('Tags saved successfully!');
      let updatedCandidateListing: any =
        candidatesListing?.crawliesPersonalDetails.map((candidate: any) => {
          console.log(candidate);
          if (selectedCrawlies.includes(candidate._id)) {
            const newAddedTags = [...candidate.tags, ...data.data?.tagCrawlies];
            const uniqueTagIds: any = [];
            const unique = newAddedTags.filter((tag) => {
              const isDuplicate = uniqueTagIds.includes(tag._id);

              if (!isDuplicate) {
                uniqueTagIds.push(tag._id);

                return true;
              }

              return false;
            });

            return {
              ...candidate,
              tags: unique,
            };
          } else {
            return candidate;
          }
        });
      console.log([...updatedCandidateListing]);
      // clearTagsAndCandidates();
      setCandidatesListing({
        crawliesPersonalDetails: updatedCandidateListing,
      });
      // refreshCandidates(data, updatedCandidateListing);
      setVisible(false);
    });
  };

  React.useEffect(() => {
    if (data) {
      setTags(data);
      setAllTags(data.map((tag: any) => tag._id));
    }
  }, [data]);

  React.useEffect(() => {
    console.log(selectedCandidateTags);
  }, [selectedCandidateTags]);
  const [visible, setVisible] = useState(false);
  const tagContent = () => {
    const options: any = tags.map((tag) => {
      return { label: tag.name, value: tag._id };
    });

    const tagRender = (props: CustomTagProps) => {
      // eslint-disable-next-line react/prop-types
      const { label, value, closable, onClose } = props;
      const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
      };
      // eslint-disable-next-line react/prop-types
      const valueText = value.split('|')[0];
      // eslint-disable-next-line react/prop-types
      const valueColor = value.split('|')[1];
      return (
        <Tag
          color={valueColor}
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          style={{ marginRight: 3 }}
        >
          {label}
        </Tag>
      );
    };

    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Select
            mode="tags"
            size={'large'}
            placeholder="Please select"
            onChange={handleChange}
            style={{
              width: 400,
              borderRadius: '10px',
              minHeight: '50px',
              marginTop: '9px',
            }}
            options={options}
            filterOption={true}
            className="tags-border"
            optionFilterProp="label"
          ></Select>
          <Button
            type="primary"
            onClick={handleSaveTags}
            style={{
              backgroundColor: '#53946F',
              marginLeft: 10,
              borderRadius: 10,
              height: 42,
              width: 100,
              borderColor: '#53946F',
            }}
          >
            Save
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      {!isEnable ? (
        <Tooltip placement="topRight" title={'Select a crawly to tag'}>
          <Button
            disabled={true}
            style={{
              borderRadius: 10,
              backgroundColor: '#C4C4C4',
              border: 'none',
              height: 44,
              width: 74,
            }}
            type="primary"
          >
            <TagFilled
              style={{
                height: 24,
                width: 24,
                color: '#5B626B',
                fontSize: 20,
                transform: 'rotate(-90deg)',
                marginLeft: 4,
              }}
            />
          </Button>
        </Tooltip>
      ) : (
        <Popover
          placement="leftBottom"
          content={tagContent}
          title="Tag user"
          trigger="click"
          visible={visible} // Control the visibility of the popover
          onVisibleChange={setVisible}
        >
          <Button
            style={{ borderRadius: 10, height: 44, width: 74 }}
            type="primary"
          >
            <TagFilled
              style={{
                color: '#fffff',
                transform: 'rotate(-90deg)',
                height: 24,
                width: 24,
                fontSize: 20,
                marginLeft: 4,
              }}
            />
          </Button>
        </Popover>
      )}
    </>
  );
};

export default TagsWidget;
