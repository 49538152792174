import { FlagIcon } from 'assets/icons/DetailedProfile';
import React, { useContext, useState } from 'react';
import { EditContext } from '../../Context/EditContext';
import { LanguagesDrawer } from '../../Edit';
import { SectionTitle } from '../../Shared';
import { SingleLanguage, LanguageComment } from './Index';

interface PropsType {
  candidate: any;
}

const Styles = {
  root: {
    padding: 15,
    // border: 'solid 1px #BC9623',
    boxShadow: '0px 0px 4px #00000040 ',
    borderRadius: 10,
    // paddingBottom: 5,
  },
  body: {
    // paddingLeft: 24,
    marginTop: 20,
  },
};

const Languages: React.FC<PropsType> = ({ candidate }) => {
  const editContext = useContext(EditContext);
  const { edit }: any = editContext;
  const [open, setOpen] = useState(false);
  const handleEdit = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <LanguagesDrawer
        open={open}
        candidate={candidate}
        handleClose={handleClose}
      />
      {(candidate?.languages?.length > 0 || edit) && (
        <div style={Styles.root}>
          <SectionTitle
            title="Languages"
            icon={FlagIcon}
            actionHandler={handleEdit}
            fontSize="16px"
            fontWeight={'600'}
            actionType="edit"
          />
          <div style={Styles.body}>
            {candidate?.languages &&
              candidate?.languages.map((language: any, index: number) => {
                return (
                  <div key={index}>
                    <SingleLanguage language={language} />
                  </div>
                );
              })}
            {candidate?.languageComments != null &&
              candidate?.languageComments && (
                <LanguageComment comment={candidate?.languageComments} />
              )}
          </div>
        </div>
      )}
    </>
  );
};

export default Languages;
