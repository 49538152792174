import { ExperienceIcon } from 'assets/icons/DetailedProfile';
import React, { useContext, useEffect, useState } from 'react';
import { EditContext } from '../../Context/EditContext';
import { ExperienceDrawer } from '../../Edit';
import ExperienceEditDrawer from '../../Edit/ExperienceEditDrawer';
import { SectionTitle } from '../../Shared';
import { SingleExperience, ExperienceComment } from './Index';

interface PropsType {
  candidate: any;
}

const Styles = {
  root: {
    padding: 15,
    // border: 'solid 1px #BC9623',
    boxShadow: '0px 0px 4px #00000040 ',
    borderRadius: 10,
    paddingBottom: 5,
  },
  body: {
    // paddingLeft: 24,
    marginTop: 20,
  },
};

const Experience: React.FC<PropsType> = ({ candidate }) => {
  const editContext = useContext(EditContext);
  const { edit }: any = editContext;
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [editIndex, setEditIndex] = useState(null);

  const handleEdit = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleEditData = (experience: any, id: any) => {
    console.log(experience);
    setEditData(experience);
    setEditIndex(id);
    setOpen(true);
  };

  useEffect(() => {
    const sortedExperiences = candidate?.experiences.sort((a: any, b: any) => {
      if (a?.current && !b?.current) {
        return -1; // a comes before b
      } else if (!a?.current && b?.current) {
        return 1; // b comes before a
      } else {
        return (b?.endYear ?? 0) - (a?.endYear ?? 0); // sort by descending endYear
      }
    });

    // Update the state or perform any actions with sortedExperiences
  }, [candidate, candidate?.experiences]);

  return (
    <>
      <ExperienceDrawer
        onUpdate={handleClose}
        open={open}
        candidate={candidate}
        handleClose={handleClose}
      />

      {(candidate?.experiences.length > 0 || edit) &&
        (candidate?.experiences[0] != null || edit) && (
          <div style={Styles.root}>
            <SectionTitle
              title="Experience"
              icon={ExperienceIcon}
              actionHandler={handleEdit}
              fontSize="16px"
              fontWeight={'600'}
              actionType="add"
              actionText="Add experience"
            />
            <div style={Styles.body}>
              {candidate?.experiences &&
                candidate?.experiences.map((experience: any, index: number) => {
                  return (
                    <div key={index}>
                      <SingleExperience
                        index={index}
                        experience={experience}
                        candidateId={candidate?._id}
                        onEdit={() => null}
                      />
                      {(experience?.comments ||
                        experience?.reasonForChange) && (
                        <ExperienceComment experience={experience} />
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        )}
    </>
  );
};

export default Experience;
