import { useGetCandidateQuery } from 'graphql/generated/graphql';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

type EditType = {
  edit: boolean;
  editProfile: () => void;
  cancelEditProfile: (data: any) => void;
  setData: (data: any) => void;
  candidate: any;
  setCandidateMatch: (data: any) => void;
  match: any;
  updatedData: any;
  setUpdatedData: (data: any) => void;
  setShowExpCompensation?: any;
  showExpCompensation?: any;
  setMatch?: any;
  activityData?: any;
  setActivityDatas?: (data: any) => void;
  isDataChanged: boolean;
  confirmPopUp: boolean;
  confirmPopUpTrigger: () => void;
  confirmPopUpCloseHandler: () => void;
  confirmPopUpOpeneHandler: () => void;
};

export const EditContext = React.createContext<EditType | null>(null);

const EditProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [edit, setEdit] = React.useState<boolean>(false);
  const [candidate, setCandidate] = React.useState<any>();
  const [match, setMatch] = React.useState<any>();
  const [updatedData, setUpdateData] = React.useState<any>({});
  const [showExpCompensation, setShowExpCompensation] =
    React.useState<any>(null);
  const [activityData, setActivityData] = React.useState<any>({});
  const [isDataChanged, setIsDataChanged] = React.useState<boolean>(false);
  const [confirmPopUp, setConfirmPopUp] = React.useState<boolean>(false);

  const confirmPopUpTrigger = () => {
    setConfirmPopUp(!confirmPopUp);
  };
  const confirmPopUpCloseHandler = () => {
    setConfirmPopUp(false);
  };
  const confirmPopUpOpeneHandler = () => {
    setConfirmPopUp(true);
  };
  const editProfile = () => {
    setEdit(true);
  };

  const cancelEditProfile = (data: any) => {
    console.log(data);
    setIsDataChanged(false);
    setEdit(false);
    setUpdateData({});
    setMatch(data);
  };

  const setData = (data: any) => {
    setCandidate(data);
  };
  const setUpdatedData = (data: any) => {
    setIsDataChanged(true);
    setUpdateData(data);
    setIsDataChanged(true);
  };
  const setCandidateMatch = (data: any) => {
    setMatch(data);
  };
  const setActivityDatas = (data: any) => {
    setActivityData(data);
  };

  return (
    <EditContext.Provider
      value={{
        edit,
        editProfile,
        match,
        setMatch,
        setCandidateMatch,
        cancelEditProfile,
        candidate,
        setData,
        updatedData,
        setUpdatedData,
        showExpCompensation,
        setShowExpCompensation,
        activityData,
        setActivityDatas,
        isDataChanged,
        confirmPopUp,
        confirmPopUpTrigger,
        confirmPopUpCloseHandler,
        confirmPopUpOpeneHandler,
        // candidateIDs,
        // setCandidateIDs
      }}
    >
      {children}
    </EditContext.Provider>
  );
};

export default EditProvider;
