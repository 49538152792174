import React, { useEffect, useState } from 'react';
import { Avatar } from 'antd';
import AvatarPlaceholder from '../../../../../../assets/icons/DetailedProfile/educationPlaceholder.svg';

interface PropsType {
  index: any;
  education: any;
  onEdit?: (id: any) => any;
  comments: string;
  candidateID: string;
}

const Styles = {
  root: {
    paddingLeft: 10,
    display: 'flex',
    gap: '6px',
    maxWidth: '320px',
    flex: '85%',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  year: {
    fontStyle: 'italic',
    fontSize: 10,
    fontWeight: 400,
    marginTop: 0,
    marginBottom: 0,
    color: '#495057',
  },
  position: {
    fontWeight: 600,
    fontSize: 11,
    marginTop: 0,
    marginBottom: 0,
    color: '#495057',
  },
  company: {
    fontWeight: 600,
    fontSize: 10,
    marginTop: 0,
    marginBottom: 0,
    color: '#495057',
  },
  avatar: {
    backgroundColor: '#F5F7F9',
    marginRight: '2px',
  },
  placeholderAvatar: {
    backgroundColor: '#F5F7F9', // Placeholder background color
    marginRight: '2px',
  },
  actionWrapper: {
    display: 'flex',
  },
};

const SingleEducation: React.FC<PropsType> = ({ education }) => {
  const [imgError, setImgError] = useState(false);

  const handleImageError = () => {
    setImgError(true);
    return false;
  };

  useEffect(() => {
    if (education?.logo == null || education?.logo == undefined) {
      setImgError(true);
    }
  }, []);

  return (
    <>
      <div style={Styles.wrapper}>
        <div style={{ ...Styles.root, wordWrap: 'break-word' }}>
          {!imgError ? (
            <Avatar
              style={{
                ...Styles.avatar,
                backgroundImage: `url(${education?.logo})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                objectFit: 'cover',
                flexShrink: 0,
              }}
              size={28}
              src={education?.logo}
              onError={handleImageError}
            />
          ) : (
            <span
              style={{
                ...Styles.placeholderAvatar,
                flexShrink: 0,
                height: '28px',
                width: '28px',
                margin: '5px',
                backgroundColor: '#f9f6f7',
                borderRadius: '50%',
              }}
            >
              <img
                style={{
                  ...Styles.placeholderAvatar,
                  height: '18px',
                  width: '18px',
                  flexShrink: 0,
                  background: '#f9f6f7',
                  margin: '5px',
                  marginTop: '2px',
                }}
                src={AvatarPlaceholder}
                alt="placeholder"
              />
            </span>
          )}
          <div className="">
            <p style={Styles.year}>
              {education?.startYear && (education.endYear || education.current)
                ? education.startYear + '-'
                : education?.startYear}
              {education.current ? 'Current' : education.endYear}
            </p>
            <p style={Styles.position}>{education.degree}</p>
            <p style={Styles.company}>
              @{education.schoolName}{' '}
              <span style={{ fontSize: 10, fontWeight: 400 }}>
                {education?.location?.city && education?.location?.country
                  ? '| ' +
                    education?.location?.city +
                    ', ' +
                    education?.location?.country
                  : education?.location?.city &&
                    education?.location?.country === ''
                  ? ' | ' + education?.location?.city
                  : ''}
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleEducation;
