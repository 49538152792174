import React, { useState, useEffect } from 'react';
import { Card, Tooltip, Button, Space, Divider } from 'antd';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  job?: any;
  trimText?: boolean;
}

const JobCard: React.FC<Props> = ({ job, trimText }) => {
  // let { url } = useRouteMatch();
  const [imgURL, setImgURL] = useState(job.company.logoUrl);

  // useEffect(() => {}, []);
  const history = useHistory();

  const handleNavigation = (url: any) => {
    history.push(url);
  };

  return (
    <>
      {job && (
        <OverLayImageBackground banner={job?.banner} className="text-center ">
          <div
            className="directory-overlay"
            style={{ height: 150, borderRadius: '16px' }}
          ></div>
        </OverLayImageBackground>
      )}
      <div className="directory-content ">
        <div className="set-job-title">
          <img
            src={imgURL}
            className=" img-thumbnail rounded-circle set-job-detail-image"
            alt="job logo"
          />
          <div>
            <h1 className="font-16  mt-4 text-primary">{job.title}</h1>

            <span
              onClick={() => {
                handleNavigation(`/backoffice/companies/${job.company._id}`);
              }}
              style={{ cursor: 'pointer' }}
            >
              <h4>{job.company.name}</h4>
            </span>
          </div>
        </div>
        <Divider
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            margin: '16px',
            width: 'calc(100% - 32px)',
            minWidth: 'calc(100% - 32px)',
          }}
        />
        {/* <Tooltip placement="left" title="Sector">
          <span className="font-10 px-2 m-2 badge badge-amber badge-pill">
            {'sector'}
          </span>
        </Tooltip> */}
        <p
          style={{ padding: 16, color: '#495057' }}
          className=" font-12"
          dangerouslySetInnerHTML={{ __html: job.description }}
        ></p>
        {/* <Space>
          {company.facebook && (
            <Link to={{ pathname: company.facebook }} target="_blank">
              <Button
                className="ant-btn-navy"
                shape="circle"
                icon={<i className="fab fa-facebook-f font-12"></i>}
              />
            </Link>
          )}
          {company.twitter && (
            <Link to={{ pathname: company.twitter }} target="_blank">
              <Button
                className="ant-btn-navy"
                shape="circle"
                icon={<i className="fab fa-twitter font-12"></i>}
              />
            </Link>
          )}
          {company.website && (
            <Link to={{ pathname: company.website }} target="_blank">
              <Button
                className="ant-btn-navy"
                shape="circle"
                icon={<i className="fas fa-link font-12"></i>}
              />
            </Link>
          )}
          {company.linkedIn && (
            <Link to={{ pathname: company.linkedIn }} target="_blank">
              <Button
                className="ant-btn-navy"
                shape="circle"
                icon={<i className="fab fa-linkedin-in font-12"></i>}
              />
            </Link>
          )}
        </Space> */}
      </div>
    </>
  );
};

const SubHeadingWithImage = styled.div`
  display: flex;
  flex-direction: 'row';
  font-weight: 500;
  margin: 10px 0px 0px 0px;
  padding: 0px;

  h4 {
    margin-left: 10px;
  }
`;

const OverLayImageBackground: any = styled.div`
  /* background-color: ${(p: any) => (p.banner ? 'white' : '#2e4a79')} ; */
  background-color: #2e4a79;
  background-image: url(${(p: any) => p.banner});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 16px 16px 0px 0px;
`;

export default JobCard;
