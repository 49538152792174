import { Editor } from '@tinymce/tinymce-react';
import { Avatar, Card, Col, Popconfirm, Popover, Row } from 'antd';
import db from '../../../../../firebase';
import { useGetMatchQuery } from '../../../../../graphql/generated/graphql';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { EditContext } from '../Context/EditContext';
import 'moment-timezone'; // Import moment-timezone

interface PropsType {
  candidate: any;
  match: any;
}

const chatColors = [
  '#1A838C',
  '#E69138',
  '#2E4A79',
  '#990000',
  '#084872',
  '#BC9623',
  '#D93535',
];

const Comments: React.FC<PropsType> = ({ candidate, match }) => {
  const messagesEndRef = useRef<any>(null);
  const messageBodyRef = useRef<null | HTMLDivElement>(null);
  const { current }: any = messagesEndRef;
  const userName = useSelector((state: any) => state.auth.userName);
  const [messages, setmessages] = useState<any>([]);
  const { matchId, jobId } = useParams<{
    matchId: string;
    jobId: string;
    id: string;
  }>();
  const [msgId, setMsgId] = useState<string | undefined>();
  const [candidateInfo, setCandidateInfo] = useState<null | any>(null);
  const [popoverVisibility, setPopoverVisibility] = useState<boolean[]>([]);

  const [recruiterCommentDescription, setrecruiterCommentDescription] =
    useState('');
  const editContext = useContext(EditContext);
  // const { data, loading, error, refetch }: any = useGetMatchQuery({
  //     variables: {
  //         matchId: matchId,
  //     },
  //     fetchPolicy: 'network-only',
  // });

  useEffect(() => {
    if (match?._id) createChat(match?._id);
  }, [match]);

  const groupBy = (array: any[], key: string) => {
    // Return the end result
    return array.reduce((result, currentValue) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    }, {}); // empty object is the initial value for result object
  };

  useEffect(() => {
    // if (data && data?.getMatch && data?.getMatch?.candidateListing) {
    // createChat();
    // }
  }, []);
  const content = (index: any, id: any, msg: any) => {
    const handleDelete = () => {
      const roomName = editContext?.match?._id;
      db.collection('new-chats')
        .doc(roomName)
        .collection('recruiter_messages')
        .doc(id)
        .delete()
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          // Handle errors, if any
          console.error('Error deleting comment:', error);
        });
    };
    const handleCancel = () => {
      setPopoverVisibility((prevVisibility) =>
        prevVisibility.map((_, i) => (i === index ? false : _))
      );
    };
    const editComment = (id: string, msg: string) => {
      setMsgId(id);
      setrecruiterCommentDescription(msg);
      handleCancel();
    };
    return (
      <div
        style={{ maxWidth: '100px', display: 'flex', flexDirection: 'column' }}
      >
        <span
          style={{ cursor: 'pointer', padding: 2 }}
          onClick={() => editComment(id, msg)}
          className="hover_comment"
        >
          Edit
        </span>
        <Popconfirm
          title="Do you confirm deletion?"
          onConfirm={handleDelete}
          onCancel={handleCancel}
          okText="Yes"
          cancelText="No"
          placement="left"
        >
          <span
            style={{ cursor: 'pointer', padding: 2 }}
            className="hover_comment"
          >
            Delete
          </span>
        </Popconfirm>
      </div>
    );
  };

  const createChat = async (matchId: any) => {
    const remembered: any = await localStorage.getItem('userName');
    const roomName = matchId;
    if (roomName) {
      db.collection('new-chats')
        .doc(roomName)
        .collection('recruiter_messages')
        .orderBy('timestamp', 'asc')
        .onSnapshot((snapshot: any) => {
          let chatData = snapshot.docs.map((s: any) => {
            return { id: s.id, ...s.data() };
          });
          const groupData = groupBy(chatData, 'name');

          let currentUserColor = false;
          for (const [k, v] of Object.entries(groupData)) {
            const random = Math.floor(Math.random() * chatColors.length);
            if (k == remembered) currentUserColor = true;

            groupData[k] = chatColors[random];
          }
          if (!currentUserColor) {
            const key: any = userName;
            const random = Math.floor(Math.random() * chatColors.length);
            groupData[key] = chatColors[random];
          }

          chatData = chatData.map((a: any) => {
            if (a.name && groupData[a.name]) {
              return {
                ...a,
                color: groupData[a.name],
              };
            }
          });
          setmessages(chatData);
          setPopoverVisibility(chatData?.map(() => false));
        });
    }
  };

  const sendMessage = (e: any) => {
    e.preventDefault();
    const roomName = editContext?.match?._id;
    if (recruiterCommentDescription) {
      db.collection('new-chats')
        .doc(roomName)
        .collection('recruiter_messages')
        .doc(msgId)
        .update({
          message: `${recruiterCommentDescription}`,
        })
        .then(() => {
          setMsgId(undefined);
          setrecruiterCommentDescription('');
        });
    }
  };

  const handleEditorChange = (content: any, editor: any) => {
    setrecruiterCommentDescription(content);
  };

  const scrollToBottom = () =>
    messageBodyRef.current?.scrollTo({
      behavior: 'smooth',
      top: messageBodyRef.current?.scrollHeight,
    });
  // if (current != null) {
  //   current.scrollIntoView({
  //     behavior: 'smooth',
  //     block: 'nearest',
  //   });
  // }

  useEffect(scrollToBottom, [messages]);

  return (
    <div>
      <Card className="card m-b-20 comments-body">
        <div className="card-body">
          <div className="inbox-wid">
            <div className="text-dark py-2" style={{ paddingBottom: '1rem' }}>
              <div className="inbox-item">
                <Row>
                  {messages.length == 0 && (
                    <p
                      style={{
                        fontSize: '90%',
                        margin: 'auto',
                        fontStyle: 'italic',
                        color: '#1a848b',
                      }}
                    >
                      Start chatting about the candidate
                    </p>
                  )}
                  <div
                    className="chat-body chat-popup"
                    ref={messageBodyRef}
                    style={{
                      width: '100%',
                      maxHeight: 635,
                    }}
                  >
                    {messages &&
                      messages.map((msg: any, index: any) => {
                        const userTimeZone =
                          Intl.DateTimeFormat().resolvedOptions().timeZone;
                        return (
                          <div key={index} className={`msg-direction-left `}>
                            <div className="msg-sent msg-style">
                              <div
                                className="sender-name"
                                style={{ fontSize: 12 }}
                              >
                                <div className="msg-info">
                                  <Avatar
                                    style={{
                                      background: `${msg.color}`,
                                    }}
                                  >
                                    {msg?.name.substring(0, 1)}
                                  </Avatar>
                                  <span className="msg-info-name">
                                    {msg?.name}
                                  </span>
                                </div>
                                <p className="inbox-item-author text-amber">
                                  {moment(
                                    new Date(
                                      msg?.timestamp?.toDate()
                                    ).toUTCString()
                                  ).format('DD/MM/YY')}{' '}
                                  at{' '}
                                  {moment(
                                    new Date(
                                      msg?.timestamp?.toDate()
                                    ).toUTCString()
                                  )
                                    .tz(userTimeZone)
                                    .format('HH:MM')}
                                </p>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <div style={{ maxWidth: '100%' }}>
                                  <p
                                    style={{
                                      fontStyle: 'italic',
                                      wordWrap: 'break-word',
                                    }}
                                    className={`msg-text-content msg-left-text-color talkbubble-left`}
                                    dangerouslySetInnerHTML={{
                                      __html: msg.message,
                                    }}
                                  ></p>
                                </div>
                                <Popover
                                  placement="top"
                                  title={''}
                                  content={() =>
                                    content(index, msg.id, msg.message)
                                  }
                                  trigger="click"
                                  style={{ width: '100%' }}
                                  visible={popoverVisibility[index]}
                                  onVisibleChange={() =>
                                    setPopoverVisibility((prevVisibility) =>
                                      prevVisibility.map((_, i) =>
                                        i === index ? !_ : _
                                      )
                                    )
                                  }
                                >
                                  <div className="y-axis">
                                    {/* <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div> */}
                                  </div>
                                </Popover>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    <div ref={messagesEndRef} />
                  </div>
                </Row>
              </div>
            </div>{' '}
          </div>
        </div>
        {msgId && (
          <>
            <Row gutter={24} className="card-footer text-muted">
              <Col span={24} className="editorCol">
                <Editor
                  value={recruiterCommentDescription}
                  apiKey="bgow6d7d89zjmvftzkn8p8gzzhkzyd551egy09ik6j400toe"
                  init={{
                    menubar: false,
                    placeholder: 'Write you comment',
                    plugins: [
                      'advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker',
                      'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
                      'save table contextmenu directionality emoticons template paste textcolor',
                    ],
                    inline: true,
                    toolbar:
                      'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons',
                    smart_paste: false,
                    force_br_newlines: true,
                    force_p_newlines: false,
                    forced_root_block: 'div',
                  }}
                  outputFormat="html"
                  onEditorChange={handleEditorChange}
                />
              </Col>
            </Row>
            <Row gutter={24} className="card-footer text-muted">
              <Col span={24}>
                <div className="chat__footer">
                  <form>
                    <button
                      type="submit"
                      className="ant-btn-primary ant-btn-round send-btn"
                      onClick={sendMessage}
                    >
                      Update
                    </button>
                  </form>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Card>
    </div>
  );
};

export default Comments;
