import React, { useEffect, useState } from 'react';
import { Avatar } from 'antd';
import AvatarPlaceholder from '../../../../../../assets/icons/DetailedProfile/experiencePlaceholder.svg';

interface PropsType {
  index: any;
  experience: any;
  candidateId: string;
  onEdit?: (id: any, experience: any) => void;
}

const Styles = {
  root: {
    paddingLeft: 10,
    display: 'flex',
    gap: '6px',
    maxWidth: '320px',
    flex: '85%',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 2,
  },
  year: {
    fontStyle: 'italic',
    fontSize: 10,
    fontWeight: 400,
    marginTop: 0,
    marginBottom: 0,
    color: '#495057',
  },
  position: {
    fontWeight: 600,
    fontSize: 11,
    marginTop: 0,
    marginBottom: 0,
    color: '#000000d9',
  },
  company: {
    fontWeight: 600,
    fontSize: 10,
    marginTop: 0,
    marginBottom: 0,
    color: '#000000d9',
  },
  avatar: {
    backgroundColor: '#F5F7F9',
  },
  placeholderAvatar: {
    backgroundColor: '#ccc', // Placeholder background color
    marginRight: '2px',
  },
  actionWrapper: {
    display: 'flex',
  },
};

const SingleExperience: React.FC<PropsType> = ({ experience }) => {
  const [imgError, setImgError] = useState(false);

  const handleImageError = () => {
    setImgError(true);
    return false;
  };

  useEffect(() => {
    if (experience?.logo == null || experience?.logo == undefined) {
      setImgError(true);
    }
  }, []);

  return (
    <>
      <div style={Styles.wrapper}>
        <div style={{ ...Styles.root, wordWrap: 'break-word' }}>
          {!imgError ? (
            <Avatar
              style={{
                ...Styles.avatar,
                backgroundImage: `url(${experience?.logo})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                objectFit: 'cover',
                flexShrink: 0,
              }}
              size={28}
              src={experience?.logo}
              onError={handleImageError}
            />
          ) : (
            <span
              style={{
                ...Styles.placeholderAvatar,
                flexShrink: 0,
                height: '28px',
                width: '28px',
                margin: '5px',
                backgroundColor: '#f9f6f7',
                borderRadius: '50%',
              }}
            >
              <img
                style={{
                  ...Styles.placeholderAvatar,
                  height: '18px',
                  width: '18px',
                  flexShrink: 0,
                  background: '#f9f6f7',
                  margin: '5px',
                  marginTop: '2px',
                }}
                src={AvatarPlaceholder}
                alt="placeholder"
              />
            </span>
          )}
          <div className="">
            <p style={Styles.year}>
              {experience?.startYear}
              {experience?.current
                ? '-Current'
                : experience?.endYear && `-${experience?.endYear}`}
            </p>
            <p style={Styles.position}>{experience?.position}</p>
            <p style={Styles.company}>
              {experience?.company && '@' + experience?.company}
              <span style={{ fontSize: 10, fontWeight: 400 }}>
                {experience?.location?.city && experience?.location?.country
                  ? '| ' +
                    experience?.location?.city +
                    ', ' +
                    experience?.location?.country
                  : experience?.location?.city &&
                    experience?.location?.country == ''
                  ? '| ' + experience?.location?.city
                  : ''}
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleExperience;
