import React, { useEffect, useContext } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from 'antd';
import CrawliesContextProvider from './Crawlies/Context/crawliesContext';

import Analytics from './Analytics/Analytics';
import NotFound from '../../components/Common/NotFound/NotFound';
import CompaniesList from './Companies/CompaniesList/CompaniesList';
import BackofficeNavLinks from '../../components/BackOffice/BackofficeNavLinks';
import { GeneralStates } from '../../store/settings/types';
import { setDrawerMenuDisplay } from '../../store/settings/actions';
import ViewCompany from './Companies/ViewCompany/ViewCompany';
import AddCompany from './Companies/AddCompany/AddCompany';
import ViewRecruiter from './Recruiters/ViewRecruiter/ViewRecruiter';
import AddRecruiter from './Recruiters/AddRecruiter/AddRecruiter';
import RecruitersList from './Recruiters/RecruitersList/RecruitersList';
import JobsList from './Jobs/JobsList/JobsList';
import ViewJob from './Jobs/ViewJob/ViewJob';
import AddJob from './Jobs/AddJob/AddJob';
import CandidateLists from './Candidates/CandidateLists/CandidateLists';
import ViewCandidate from './Candidates/ViewCandidate/ViewCandidate';
import ViewCandidateProfile from './Candidates/ViewCandidateProfile/ViewCandidateProfile';
import AddCandidate from './Candidates/AddCandidate/AddCandidate';
import ManageJobPage from './Jobs/ManageJobPage/ManageJobPage';
import AddRecruiterToJobs from './Recruiters/AddRecruiterToJobs/AddRecruiterToJobs';
import ResetPassword from './Recruiters/ResetPassword/ResetPassword';
import NewCandidates from './Candidates/NewCandidates/index';
import SkillLists from './Skills/SkillLists/SkillLists';
import CrawliesLists from './Crawlies/CrawliesLists';
import ActivityTable from './Candidates/ActivityTable/ActivityTable';
import useAdmins from 'hooks/useAdmins';
import useRecruiters from 'hooks/useRecruiters';
import useCandidates from 'hooks/useCandidates';
import EditProvider, {
  EditContext,
} from './Candidates/Profile/Context/EditContext';
// /backoffice/candidates
const BackOffice: React.FC = () => {
  const { path, url } = useRouteMatch();
  const { drawerMenuDisplayed } = useSelector(
    (state: GeneralStates) => state.settings
  );
  const dispatch = useDispatch();

  const candidates = useCandidates();
  const recruiters = useRecruiters();
  const admins = useAdmins();

  useEffect(() => {
    if (candidates && candidates.length > 0) {
      localStorage.setItem('candidates', JSON.stringify(candidates));
    }
    if (recruiters && recruiters.length > 0) {
      localStorage.setItem('recruiters', JSON.stringify(recruiters));
    }
    if (admins && admins.length > 0) {
      localStorage.setItem('admins', JSON.stringify(admins));
    }
  }, [candidates, recruiters, admins]);

  return (
    <>
      <Switch>
        <Route exact path={path} component={Analytics} />
        <Route exact path={`${path}/companies/add`} component={AddCompany} />
        <Route path={`${path}/companies/:companyId`}>
          {/* <EditProvider> */}
          <ViewCompany />
          {/* </EditProvider> */}
        </Route>
        <Route path={`${path}/companies`} component={CompaniesList} />
        <Route exact path={`${path}/recruiters/add`} component={AddRecruiter} />
        <Route
          exact
          path={`${path}/recruiters/companies/jobs/add`}
          component={AddRecruiterToJobs}
        />
        <Route
          exact
          path={`${path}/recruiters/:id/resetPassword`}
          component={ResetPassword}
        />
        <Route path={`${path}/recruiters/:id`} component={ViewRecruiter} />
        <Route path={`${path}/recruiters/`} component={RecruitersList} />
        <Route exact path={`${path}/jobs/add`} component={AddJob} />
        <Route exact path={`${path}/jobs/:jobId`}>
          {/* <EditProvider> */}
          <ViewJob />
          {/* </EditProvider> */}
        </Route>
        <Route exact path={`${path}/jobs/:jobId/candidates/:matchId`}>
          {/* <EditProvider> */}
          <ViewCandidate />
          {/* </EditProvider> */}
        </Route>
        <Route exact path={`${path}/jobs/:jobId/candidates/:matchId/Activity`}>
          {/* <EditProvider> */}
          <ActivityTable />
          {/* </EditProvider> */}
        </Route>
        <Route exact path={`${path}/candidates/:candidateId/Activity`}>
          {/* <EditProvider> */}
          <ActivityTable />
          {/* </EditProvider> */}
        </Route>
        <Route
          exact
          path={`${path}/joblandingpage/:jobId`}
          component={ManageJobPage}
        />
        <Route exact path={`${path}/jobs/edit/:jobId`} component={AddJob} />
        <Route path={`${path}/jobs/`}>
          {/* <EditProvider> */}
          <JobsList />
          {/* </EditProvider> */}
        </Route>
        <Route
          exact={true}
          path={`${path}/new-candidates`}
          component={NewCandidates}
        />

        <Route exact path={`${path}/candidates/add`} component={AddCandidate} />

        <Route exact path={`${path}/candidates/:id`}>
          {/* <EditProvider> */}
          <ViewCandidateProfile />
          {/* </EditProvider> */}
        </Route>
        {/* /backoffice/candidates */}
        <Route path={`${path}/candidates/`} component={CandidateLists} />

        <Route path={`${path}/skills/`} component={SkillLists} />
        <CrawliesContextProvider>
          <Route path={`${path}/crawlies/`} component={CrawliesLists} />
        </CrawliesContextProvider>
        <Route path="*" exact={true} component={NotFound} />
      </Switch>

      <Drawer
        title="Backoffice menu"
        placement="right"
        closable={true}
        onClose={() => dispatch(setDrawerMenuDisplay(false))}
        visible={drawerMenuDisplayed}
        className="menu-drawer"
      >
        <BackofficeNavLinks
          url={url}
          closeDrawer={() => dispatch(setDrawerMenuDisplay(false))}
        />
      </Drawer>
    </>
  );
};

export default BackOffice;
