import { useState, useEffect } from 'react';
import { useCompaniesQuery } from '../graphql/generated/graphql';

const useCompanies = () => {
  const [companies, setCompanies]: any = useState(null);
  const { data, loading, error } = useCompaniesQuery();

  const loadCompanies = () => {
    if (!loading && data) {
      console.log(data.companies);
      setCompanies([...data.companies]);
    }
  };

  useEffect(() => {
    loadCompanies();
  }, [data]);

  return companies;
};

export default useCompanies;
