import { Row, Space, Spin, message } from 'antd';
import Upload, { UploadProps } from 'antd/es/upload';
import React, { useEffect, useState } from 'react';
import {
  DeleteWhiteIcon,
  UploadWhite,
  ViewIcon2,
} from 'assets/icons/DetailedProfile';
import { useAddJobMutation } from 'graphql/generated/graphql';
import { useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

interface Props {
  job: any;
}

const ProgressReport: React.FC<Props> = ({ job }) => {
  const [report, setReport] = useState('none');
  const [addJobMutation, { loading }] = useAddJobMutation();
  const params: any = useParams();

  const onUpdate: UploadProps['onChange'] = (e: any) => {
    const isLt5M = e?.file?.size / 1024 / 1024 > 10;
    if (e.file.status === 'done') {
      if (!isLt5M) {
        setReport(e.file.response.secure_url);
        addReport(e.file.response.secure_url);

        message.success('Progress Report Uploaded!');
      } else {
        // setFileAdded(false);
        message.error('Attachment must be smaller than 10MB!');
        console.log('Attachment must be smaller than 10MB!');
        console.log('e', e);
      }
    }
  };

  const clearReport = () => {
    setReport('none');
    addReport('none');

    message.success('Report Deleted');
  };

  const viewReport = () => {
    window.open(
      report?.includes('.pdf')
        ? `${report}#toolbar=0&scrollbar=0&view=fitH&view=fitV`
        : `https://docs.google.com/gview?url=${report}&embedded=true`
    );
  };

  const addReport = (report: any) => {
    let payload = {
      title: job?.getJobDetails?.title,
      banner: job?.getJobDetails?.banner,
      mobileBanner: job?.getJobDetails?.mobileBanner,
      description: job?.getJobDetails?.description,
      isAnonymous:
        job?.getJobDetails?.isAnonymous !== null
          ? job?.getJobDetails?.isAnonymous
          : false,
      hideSalary: job?.getJobDetails?.hideSalary,
      countries: job?.getJobDetails?.countries.map(
        (country: { _id: any }) => country._id
      ),
      salaryPackage: job?.getJobDetails?.salaryPackage,
      requiredSkills: job?.getJobDetails?.requiredSkills.map(
        (skill: { _id: any }) => skill._id
      ),
      customSkills: [],
      currencyId: job?.getJobDetails?.currency._id,
      recruiters: job?.getJobDetails?.recruiters.map(
        (recruiter: { _id: any }) => recruiter._id
      ),
      leadRecruiter: job?.getJobDetails?.leadRecruiter._id,
      fields: job?.getJobDetails?.fields.map(
        (field: { _id: any }) => field._id
      ),
      contractType: job?.getJobDetails?.contractType._id,
      companyId: job?.getJobDetails?.company._id,
      yearlyCompensation: {
        currency: job?.getJobDetails?.yearlyCompensation.currency,
        total: job?.getJobDetails?.yearlyCompensation.total,
        base: job?.getJobDetails?.yearlyCompensation.base,
        onTargetEarnings:
          job?.getJobDetails?.yearlyCompensation.onTargetEarnings,
        shortTermIncentives:
          job?.getJobDetails?.yearlyCompensation.shortTermIncentives,
        longTermIncentives:
          job?.getJobDetails?.yearlyCompensation.longTermIncentives,
        other: job?.getJobDetails?.yearlyCompensation.other,
        comments: job?.getJobDetails?.yearlyCompensation.comments,
      },
      assessment: job?.getJobDetails?.assessment,
      progressReport: report,
      targetCompanies: job?.getJobDetails?.targetCompanies.map(
        (company: { _id: any }) => company._id
      ),
      language: job?.getJobDetails?.language,
      jobId: job?.getJobDetails?._id,
    };

    if (job && Object.keys(job).length !== 0) {
      // setHasError(true);
      addJobMutation({
        variables: {
          ...payload,
        },
      });
    }
  };

  useEffect(() => {
    setReport(job?.getJobDetails?.progressReport || 'none');
  }, [job]);

  return (
    <Row
      style={{
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        background: '#900',
        minHeight: '38px',
        color: '#ffffff',
        width: '100%',
        margin: '0px auto 10px auto',
        padding: ' 8.5px 34px',
        borderRadius: '18px',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: 'normal',
      }}
    >
      Progress Report
      {loading ? (
        <Spin
          indicator={
            <LoadingOutlined style={{ fontSize: 22, color: '#fff' }} spin />
          }
        />
      ) : report === 'none' ? (
        <Upload
          style={{
            display: 'none',
          }}
          action="https://api.cloudinary.com/v1_1/blonk-group/raw/upload"
          onChange={onUpdate}
          showUploadList={false}
          data={{
            upload_preset: 'cqdbrmkw',
          }}
          accept=".docx,.pdf"
          multiple={false}
        >
          <img
            src={UploadWhite}
            alt="Upload icon"
            style={{ cursor: 'pointer' }}
          />
        </Upload>
      ) : (
        <Space>
          <img
            src={ViewIcon2}
            alt="View icon"
            onClick={viewReport}
            style={{ cursor: 'pointer' }}
          />
          <img
            src={DeleteWhiteIcon}
            width="16px"
            alt="Delete icon"
            onClick={clearReport}
            style={{ cursor: 'pointer' }}
          />
        </Space>
      )}
    </Row>
  );
};

export default ProgressReport;
