import { Typography } from 'antd';
import { CompensationIcon } from 'assets/icons/DetailedProfile';
import Inspiringcompany from 'assets/icons/profilesvgs/Inspiringcompany.svg';
import React, { useContext, useEffect, useState } from 'react';
import { EditContext } from '../Context/EditContext';
import { YearlyCompensationDrawer } from '../Edit';
import { SectionTitle } from '../Shared';
import { numberWithCommas } from 'helpers/numberConvert';
import { Switch } from 'antd';
import { useCandidateShowCompensationMutation } from 'graphql/generated/graphql';
const { Title } = Typography;

interface PropsType {
  candidate: any;
  match?: any;
  showSwitch?: any;
}

const Styles = {
  root: {
    padding: 15,
    boxShadow: '0px 0px 4px #00000040 ',
    borderRadius: 10,
    // paddingBottom: 5,
  },
  body: {
    paddingLeft: 24,
    marginTop: 10,
  },
  subBody: {
    paddingLeft: 16,
    marginTop: 10,
  },
  smallText: {
    fontSize: 14,
    fontWeight: 400,
    color: '#495057',
  },
  commentBox: {
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: '#E7F1F9',
    marginTop: 12,
    borderRadius: 9,
    marginBottom: 10,
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.14)',
    border: 'solid 0.5px #DADADA',
  },
};

const Fields = [
  { key: 'base', value: 'Base' },
  { key: 'onTargetEarnings', value: 'On target earnings' },
  { key: 'shortTermIncentives', value: 'Short term incentives' },
  { key: 'longTermIncentives', value: 'Long term incentives' },
  { key: 'other', value: 'Other' },
];

const YearlyCompensation: React.FC<PropsType> = ({
  candidate,
  match,
  showSwitch,
}) => {
  const [open, setOpen] = useState(false);
  const editContext = useContext(EditContext);

  const {
    edit,
    updatedData,
    setShowExpCompensation,
    showExpCompensation,
  }: any = editContext;

  const handleClose = () => {
    setOpen(false);
  };

  const openEdit = () => {
    setOpen(true);
  };

  //conversion function
  const conversion = (value: any) => {
    const roundFigure = value >= 1000 ? `${(value / 1000).toFixed(1)}` : value;
    const modulos = roundFigure % 1;
    if (value >= 1000 && modulos > 0.0) {
      return value >= 1000 ? `${(value / 1000).toFixed(1)}` + 'k' : value;
    } else if (value >= 1000 && modulos == 0.0) {
      const finalValue =
        (value / 1000).toFixed(1).split('.')[1] == '0'
          ? (value / 1000).toFixed(0) + 'k'
          : roundFigure + 'k';
      return finalValue;
    } else {
      return value;
    }
  };
  const Discount = () => {
    if (candidate?.yearlyCompensation != null) {
      const { current, expected } = candidate?.yearlyCompensation;
      const currentTotal = current.total;
      const expectedTotal = expected.total;
      const discount = parseInt(
        ((expectedTotal / currentTotal) * 100 - 100).toFixed(0)
      );

      if (isNaN(discount)) {
        return null;
      }

      return (
        <span style={{ color: '#00AC07', fontSize: 9, marginLeft: '2px' }}>
          ({(discount < 0 ? '' : '+') + discount}%)
        </span>
      );
    }

    return null;
  };

  const calcaluteTotal = (expected: any) => {
    if (!expected) {
      return null;
    }
    let total: any = 0;
    for (const key in expected) {
      if (expected.hasOwnProperty(key)) {
        if (expected[key] && expected[key] !== 'Compensation') {
          total += Number(expected[key]);
        }
      }
    }
    total = total.toString();
    console.log(total);
    if (total == 0) return null;
    return conversion(total);
  };

  const anyExpectedCompensation = (expected: any) => {
    let flag = false;

    for (const key in expected) {
      if (expected.hasOwnProperty(key)) {
        if (
          (expected[key] && expected[key] !== 'Compensation') ||
          expected[key] != null
        ) {
          flag = true;
          break;
        }
      }
    }
    return flag;
  };

  const [showCompensationMutation, { data, loading, error }] =
    useCandidateShowCompensationMutation();
  const [showCurrentCompensation, setShowCurrentCompensation] = useState(false);
  const [showExpectedCompensation, setShowExpectedCompensation] =
    useState(false);

  const compensationCurrenetHandler = () => {
    const updatedShowCompensation = !showCurrentCompensation;
    setShowCurrentCompensation(updatedShowCompensation);
    showCompensationMutation({
      variables: {
        matchId: match?._id,
        showCurrentCompensation: updatedShowCompensation,
      },
    }).then(({ data }) => {
      console.log(data);
    });
  };
  const compensationExpectedHandler = () => {
    const updatedShowCompensation = !showExpectedCompensation;
    setShowExpectedCompensation(updatedShowCompensation);
    setShowExpCompensation(updatedShowCompensation);
    showCompensationMutation({
      variables: {
        matchId: match?._id,
        showExpectedCompensation: !updatedShowCompensation,
      },
    }).then(({ data }) => {
      console.log(data);
    });
  };
  const formattedCurrentComment =
    candidate?.yearlyCompensation?.currentComments?.replace(/\n/g, '<br />');
  const formattedExpectedComment =
    candidate?.yearlyCompensation?.comments?.replace(/\n/g, '<br />');
  //setDefault Value In states on match changes
  useEffect(() => {
    if (match?.showCurrentCompensation !== null) {
      setShowCurrentCompensation(match?.showCurrentCompensation);
    }
    if (showExpCompensation !== null) {
      setShowExpectedCompensation(showExpCompensation);
    } else {
      setShowExpectedCompensation(match?.showExpectedCompensation);
    }
  }, [match, showExpCompensation]);

  return (
    <>
      {((candidate?.yearlyCompensation != null &&
        candidate?.yearlyCompensation?.current != null &&
        (candidate?.yearlyCompensation?.current?.total?.length > 0 ||
          candidate?.yearlyCompensation?.expected?.total?.length > 0)) ||
        edit) && (
        <>
          <YearlyCompensationDrawer
            open={open}
            candidate={candidate}
            handleClose={handleClose}
            match={match}
          />
          <div style={Styles.root}>
            <SectionTitle
              actionHandler={openEdit}
              title="Yearly compensation details"
              icon={CompensationIcon}
              // lastEndIcon={Inspiringcompany}
              fontSize="16px"
              fontWeight={'600'}
              actionType="edit"
            />

            {(candidate?.yearlyCompensation?.current.total != null ||
              editContext?.edit ||
              Fields.some(
                (field) =>
                  candidate?.yearlyCompensation?.current[field.key] != null
              )) && (
              <div style={Styles.body}>
                <span
                  style={{ fontSize: 14, color: '#2E4A79', fontWeight: '600' }}
                >
                  Current / Last compensation{' '}
                  {candidate?.yearlyCompensation?.currency && (
                    <span style={{ fontWeight: '400' }}>
                      {' '}
                      {`(in ${candidate?.yearlyCompensation?.currency})`}
                    </span>
                  )}
                </span>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {(candidate?.yearlyCompensation?.current?.total != null ||
                    editContext?.edit) && (
                    <>
                      <span style={Styles.smallText}>Total</span>
                      <span
                        style={{
                          ...Styles.smallText,
                          // paddingRight: 60,
                          display: 'block',
                          width: 75,
                          color: '#2E4A79',
                        }}
                      >
                        {candidate?.yearlyCompensation?.current?.total &&
                          numberWithCommas(
                            candidate?.yearlyCompensation?.current?.total
                          ) + 'k'}
                        {/* {candidate?.yearlyCompensation?.currency} */}
                      </span>
                    </>
                  )}
                </div>
                <div style={Styles.subBody}>
                  {Fields?.map((field: any, index: any) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {(candidate?.yearlyCompensation?.current[field?.key] !=
                        null ||
                        editContext?.edit) && (
                        <>
                          <span
                            style={{ ...Styles.smallText, paddingLeft: '40px' }}
                          >
                            {candidate?.yearlyCompensation?.current[
                              field?.key
                            ] > '0'
                              ? field?.value
                              : editContext?.edit
                              ? field?.value
                              : ''}
                          </span>
                          <span
                            style={
                              field?.value == 'Other'
                                ? {
                                    ...Styles.smallText,
                                    color: '#2E4A79',
                                    textAlign: 'right',
                                    width: '240px',
                                    wordWrap: 'break-word',
                                  }
                                : { ...Styles.smallText, color: '#2E4A79' }
                            }
                          >
                            {/* for AlphaNumeric current */}

                            {candidate?.yearlyCompensation?.current[field.key] >
                              '0' && field?.value !== 'Other'
                              ? numberWithCommas(
                                  candidate?.yearlyCompensation?.current[
                                    field.key
                                  ]
                                ) + 'k'
                              : field?.value == 'Other'
                              ? numberWithCommas(
                                  candidate?.yearlyCompensation?.current[
                                    field.key
                                  ]
                                )
                              : ''}

                            {/* for Numeric current */}

                            {/* {candidate?.yearlyCompensation?.current[field?.key] >
                  '0' && field?.value
                  ? numberWithCommas(
                    candidate?.yearlyCompensation?.current[
                    field?.key
                    ]
                  ) + 'k'
                  : ''} */}
                          </span>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {candidate?.yearlyCompensation?.currentComments != null &&
              candidate?.yearlyCompensation?.currentComments?.length > 0 && (
                <>
                  <div style={Styles.commentBox}>
                    <p
                      style={{
                        fontSize: 14,
                        marginTop: 0,
                        fontWeight: 400,
                        color: '#012653',
                        marginBottom: 3,
                      }}
                    >
                      Comments
                    </p>
                    <span
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                        color: '#495057',
                        fontStyle: 'italic',
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: formattedCurrentComment,
                        }}
                      />
                      {/* <i>{candidate?.yearlyCompensation?.currentComments}</i> */}
                    </span>
                  </div>
                </>
              )}

            {(candidate?.yearlyCompensation?.expected?.total || //
              editContext?.edit ||
              Fields.some(
                (field) =>
                  candidate?.yearlyCompensation?.expected[field.key] != null &&
                  candidate?.yearlyCompensation?.expected[field.key] !== ''
              )) && (
              <div style={Styles.body}>
                <span
                  style={{ fontSize: 14, color: '#2E4A79', fontWeight: '600' }}
                >
                  {(anyExpectedCompensation(
                    candidate?.yearlyCompensation?.expected
                  ) ||
                    candidate?.yearlyCompensation?.expected?.total) && (
                    <>
                      {' '}
                      Expected compensation{' '}
                      {candidate?.yearlyCompensation?.currency && (
                        <span
                          style={{
                            fontSize: 14,
                            color: '#2E4A79',
                            fontWeight: '400',
                          }}
                        >{`(in ${candidate?.yearlyCompensation?.currency})`}</span>
                      )}
                    </>
                  )}
                </span>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {!candidate?.yearlyCompensation?.expected?.total ? (
                    <>
                      {anyExpectedCompensation(
                        candidate?.yearlyCompensation?.expected
                      ) &&
                      !!calcaluteTotal(
                        candidate?.yearlyCompensation?.expected
                      ) ? ( //
                        <>
                          <span style={Styles.smallText}>Total</span>
                          <span
                            style={{
                              ...Styles.smallText,
                              display: 'block',
                              width: 75,
                              color: '#2E4A79',
                            }}
                          >
                            {calcaluteTotal(
                              candidate?.yearlyCompensation?.expected
                            ) + 'k'}
                            {/* {candidate?.yearlyCompensation?.currency} */}
                            {<Discount />}
                          </span>
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    <>
                      <span style={Styles.smallText}>Total</span>
                      <span
                        style={{
                          ...Styles.smallText,
                          display: 'block',
                          width: 75,
                          color: '#2E4A79',
                        }}
                      >
                        {conversion(
                          candidate?.yearlyCompensation?.expected?.total
                        ) + 'k'}
                        {/* {candidate?.yearlyCompensation?.currency} */}
                        {<Discount />}
                      </span>
                    </>
                  )}
                </div>
                <div style={Styles.subBody}>
                  {Fields.map((field: any, index: any) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {(candidate?.yearlyCompensation?.expected[field?.key] !=
                        null ||
                        editContext?.edit) && (
                        <>
                          <span
                            style={{ ...Styles.smallText, paddingLeft: '40px' }}
                          >
                            {candidate?.yearlyCompensation?.expected[
                              field.key
                            ] > '0'
                              ? field?.value
                              : editContext?.edit
                              ? field?.value
                              : ''}
                          </span>
                          <span
                            style={
                              field?.value == 'Other'
                                ? {
                                    ...Styles.smallText,
                                    color: '#2E4A79',
                                    textAlign: 'right',
                                    width: '240px',
                                    wordWrap: 'break-word',
                                  }
                                : { ...Styles.smallText, color: '#2E4A79' }
                            }
                          >
                            {/* for alphanumeric other */}
                            {candidate?.yearlyCompensation?.expected[
                              field.key
                            ] > '0' && field?.value !== 'Other'
                              ? numberWithCommas(
                                  candidate?.yearlyCompensation?.expected[
                                    field.key
                                  ]
                                ) + 'k'
                              : field?.value == 'Other'
                              ? numberWithCommas(
                                  candidate?.yearlyCompensation?.expected[
                                    field.key
                                  ]
                                )
                              : ''}
                            {/* For Numeric value */}
                            {/* {candidate?.yearlyCompensation?.expected[
                    field.key
                  ] > '0' && field?.value
                    ? numberWithCommas(
                        candidate?.yearlyCompensation?.expected[field.key]
                      ) + 'k'
                    : ''} */}
                          </span>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {showSwitch && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 20,
                }}
              >
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: '500',
                    color: '#495057',
                    fontFamily: 'poppins',
                  }}
                >
                  Hide compensation details
                </span>
                <Switch
                  size="small"
                  onChange={compensationExpectedHandler}
                  checked={showExpectedCompensation}
                />
              </div>
            )}

            {candidate?.yearlyCompensation?.comments != null &&
              candidate?.yearlyCompensation?.comments?.length > 0 && (
                <>
                  <div style={Styles.commentBox}>
                    <p
                      style={{
                        fontSize: 14,
                        marginTop: 0,
                        fontWeight: 400,
                        color: '#012653',
                        marginBottom: 3,
                      }}
                    >
                      Comments
                    </p>
                    <span
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                        color: '#495057',
                        fontStyle: 'italic',
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: formattedExpectedComment,
                        }}
                      />
                      {/* <i>{candidate?.yearlyCompensation?.comments}</i> */}
                    </span>
                  </div>
                </>
              )}
          </div>
        </>
      )}
    </>
  );
};

export default YearlyCompensation;
