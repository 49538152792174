import {
  Button,
  Col,
  Modal,
  notification,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Switch,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import './AssessmentTableStyles.scss';
import TextArea from 'antd/lib/input/TextArea';
import debounce from 'lodash/debounce';
import { useMutation } from '@apollo/client';
import { useUpdateAssessmentsMutation } from 'graphql/generated/graphql';
import NoData from 'components/UI/NoData/NoData';

const ratingOptions = [
  { label: '', value: 'Low' },
  { label: '', value: 'Medium' },
  { label: '', value: 'High' },
];

const colors: { [key: string]: string } = {
  Experiences: '#990000CC',
  Traits: '#2E4A79E5',
  Drivers: '#008D8B',
};

interface ExperienceItem {
  title: string;
  level: string;
  comment: string;
}

interface PropsType {
  open: boolean;
  setOpen: any;
  view: string;
  setView: any;
  candidateAssessment: any;
  matchId: string;
}

const GetRow = ({
  rowData,
  index,
  view,
  TableData,
  setTableData,
}: {
  rowData: ExperienceItem;
  index: number;
  view: string;
  TableData: ExperienceItem[];
  setTableData: any;
}) => {
  const { title, level, comment } = rowData;

  const onRadioChange = (index: number, value: string) => {
    const updatedData = [...TableData];
    updatedData[index].level = value;
    setTableData(updatedData);
  };

  const onCommentChange = (index: number, value: string) => {
    const updatedData = [...TableData];
    updatedData[index].comment = value;
    setTableData(updatedData);
  };

  return (
    <>
      <Col
        span={5}
        style={{
          backgroundColor: colors[view],
          color: 'white',
          height: '60px',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          fontSize: '12px',
          lineHeight: '16px',
        }}
      >
        {title}
      </Col>
      <Col span={8}>
        <Radio.Group
          className={`radio-group-with-gap ${view}`}
          size="large"
          options={ratingOptions}
          onChange={(e) => onRadioChange(index, e.target.value)}
          value={level}
          optionType="button"
          buttonStyle="solid"
        />
      </Col>
      <Col span={11}>
        <TextArea
          rows={3}
          placeholder="Comments"
          bordered={false}
          value={comment}
          maxLength={140}
          onChange={(e) => onCommentChange(index, e.target.value)}
          style={{
            minHeight: 60,
            width: 'auto !important',
            resize: 'none',
            backgroundColor: colors[view],
            borderRadius: '8px',
            color: 'white',
            fontSize: '12px',
            lineHeight: '16px',
            display: 'flex',
            padding: '2px 8px',
            flex: ' 1 0 0',
          }}
        />
      </Col>
    </>
  );
};

const AssessmentTableModal: React.FC<PropsType> = ({
  open,
  setOpen,
  view,
  candidateAssessment,
  setView,
  matchId,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [TableData, setTableData] = useState<any>([]);
  const [hideExperience, setHideExperience] = useState(false);
  const [hideTraits, setHideTraits] = useState(false);
  const [hideDrivers, setHideDrivers] = useState(false);

  useEffect(() => {
    if (view === 'Experiences') {
      setTableData(candidateAssessment.experience);
      // setHideExperience(candidateAssessment?.hideExperience || false);
    } else if (view === 'Traits') {
      setTableData(candidateAssessment.traits);
      // setHideTraits(candidateAssessment?.hideTraits || false);
    } else {
      setTableData(candidateAssessment.drivers);
      // setHideDrivers(candidateAssessment?.hideDrivers || false);
    }
  }, [view]);

  useEffect(() => {
    setHideExperience(candidateAssessment?.hideExperience || false);
    console.log('hide aaaa', candidateAssessment?.hideExperience, 'true', true);
    setHideTraits(candidateAssessment?.hideTraits || false);
    setHideDrivers(candidateAssessment?.hideDrivers || false);
  }, [candidateAssessment]);

  // useEffect(() => {
  //   console.log(TableData, '----------bs');
  //   if (TableData?.length > 0) {
  //     const sortedArray = [...TableData].sort((a: any, b: any) => {
  //       const titleA = a.title.toLowerCase();
  //       const titleB = b.title.toLowerCase();
  //       if (titleA < titleB) {
  //         return -1;
  //       }
  //       if (titleA > titleB) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //     setTableData(sortedArray);
  //   }
  //   console.log(TableData, '-----------as');
  // }, [TableData && TableData[0]?.title]);

  const [updateAssessmentsMutation] = useUpdateAssessmentsMutation();

  const handleOk = async () => {
    try {
      setConfirmLoading(true);
      // Call the mutation function with the current mutation variables
      const result = await updateAssessmentsMutation({
        variables: {
          ...candidateAssessment,
          matchId: matchId,
          hideExperience: hideExperience,
          hideTraits: hideTraits,
          hideDrivers: hideDrivers,
        },
      });

      // Handle success
      setTimeout(() => {
        // setOpen(false);
        notification['success']({
          message: `${view} updated!`,
          duration: 3,
        });
        setConfirmLoading(false);
        // window.location.reload();
      }, 2000);
    } catch (error) {
      // Handle error
      console.error('Mutation error:', error);
      setTimeout(() => {
        setConfirmLoading(false);
      }, 2000);
    }
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };

  const hideAssessment = (checked: boolean) => {
    if (view === 'Experiences') {
      setHideExperience(checked);
    } else if (view === 'Traits') {
      setHideTraits(checked);
    } else {
      setHideDrivers(checked);
    }
  };

  const switchView = (toView: string) => {
    console.log(`switch to ${toView}`);
    setView(toView);
  };

  return (
    <>
      <Modal
        width={800}
        title={view}
        centered
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          <>
            <Space>
              <Typography>Hide {view.toLowerCase()}</Typography>
              <Switch
                size="small"
                onChange={hideAssessment}
                checked={
                  view === 'Experiences'
                    ? hideExperience
                    : view === 'Traits'
                    ? hideTraits
                    : hideDrivers
                }
              />
            </Space>

            <Space>
              <Button
                key="back"
                shape="round"
                onClick={handleCancel}
                style={{
                  borderColor: '#990000',
                  color: '#990000',
                }}
              >
                Cancel
              </Button>

              <Button
                key="submit"
                type="primary"
                shape="round"
                loading={confirmLoading}
                onClick={handleOk}
                style={{
                  backgroundColor: '#BC9623',
                  border: 'none',
                }}
              >
                Submit
              </Button>
            </Space>
          </>
        }
      >
        <Row className="view-switches">
          {candidateAssessment?.experience?.length !== 0 && (
            <Button
              className={view === 'Experiences' ? 'Experience' : ''}
              onClick={() => switchView('Experiences')}
            >
              Experiences
            </Button>
          )}
          {candidateAssessment?.traits?.length !== 0 && (
            <Button
              className={view === 'Traits' ? 'Traits' : ''}
              onClick={() => switchView('Traits')}
            >
              Traits
            </Button>
          )}
          <Button
            className={view === 'Drivers' ? 'Drivers' : ''}
            onClick={() => switchView('Drivers')}
          >
            Drivers
          </Button>
        </Row>

        <Row className="table-row" align="middle">
          {TableData?.length > 0 ? (
            <>
              <Col span={24} offset={6}>
                <Space size={37}>
                  <Typography>Low</Typography>
                  <Typography>Medium</Typography>
                  <Typography style={{ marginLeft: '-6px' }}>High</Typography>
                  <Typography>Comments</Typography>
                </Space>
              </Col>
              {TableData?.map((row: any, index: any) => {
                return (
                  <GetRow
                    key={index}
                    rowData={row}
                    index={index}
                    view={view}
                    TableData={TableData}
                    setTableData={setTableData}
                  />
                );
              })}
            </>
          ) : (
            <NoData message={`No required ${view} added`} />
          )}
        </Row>
      </Modal>
    </>
  );
};

export default AssessmentTableModal;
