import { AssessmentTableIcon } from 'assets/icons/DetailedProfile';
import React, { useEffect, useState } from 'react';
import './AssessmentTableStyles.scss';
import { SectionTitle } from '../Shared';
import { Button, Space } from 'antd';
import AssessmentTableModal from './AssessmentTableModal';
import { updateElementAccess } from 'typescript';

interface PropsType {
  match: any;
  jobData: any;
}

interface ExperienceItem {
  title: string;
  level: string;
  comment: string;
}

const driversList: ExperienceItem[] = [
  { title: 'Push', level: '', comment: '' },
  { title: 'Pull', level: '', comment: '' },
];

const Styles = {
  root: {
    padding: '12px 16px',
    boxShadow: '0px 0px 4px #00000040 ',
    borderRadius: 10,
  },
  body: {
    marginTop: 16,
    display: 'flex',
  },
};

const AssessmentTable: React.FC<PropsType> = ({ match, jobData }) => {
  console.log('here is m1', match);
  console.log('here is m2', jobData?.getJobDetails);

  const [openTable, setOpenTable] = useState(false);
  const [view, setView] = useState('');
  const [data, setData] = useState({});

  console.log('here is m3 ', data);
  console.log('here is view ', view);

  const handleClick = (ViewOption: string) => {
    setView(ViewOption);
    setOpenTable(true);
  };

  useEffect(() => {
    if (match?.AssessmentReport && jobData?.getJobDetails?.assessment) {
      const transformedExperience: ExperienceItem[] =
        jobData.getJobDetails.assessment.experience?.map((exp: any) => ({
          title: exp.title,
          level: '',
          comment: '',
        })) || [];

      const transformedTraits: ExperienceItem[] =
        jobData.getJobDetails.assessment.traits?.map((trait: any) => ({
          title: trait.title,
          level: '',
          comment: '',
        })) || [];

      const newAssessment = {
        experience: transformedExperience,
        traits: transformedTraits,
      };

      let updatedExperience = match?.AssessmentReport?.experience.map(
        (exp: any) => ({
          title: exp.title,
          level: exp.level,
          comment: exp.comment,
        })
      );

      updatedExperience = jobData?.getJobDetails?.assessment?.experience?.map(
        (exp: any, i: any) => ({ ...updatedExperience[i], title: exp.title })
      );

      let updatedTraits = match?.AssessmentReport?.traits.map((trait: any) => ({
        title: trait.title,
        level: trait.level,
        comment: trait.comment,
      }));

      // updatedTraits =
      //   jobData.getJobDetails.assessment.traits?.map((trait: any) => ({
      //     title: trait.title,
      //     // level: '',
      //     // comment: '',
      //   }))

      updatedTraits = jobData?.getJobDetails?.assessment?.traits?.map(
        (trait: any, i: any) => ({ ...updatedTraits[i], title: trait.title })
      );
      //////////////////////////////////////////////////////////////////////////////////
      ///////////////////////////////////////////////////////////////
      const filteredExperience = newAssessment.experience.filter(
        (exp) =>
          !updatedExperience.some((updated: any) => updated.title === exp.title)
      );

      const filteredTraits = newAssessment.traits.filter(
        (trait) =>
          !updatedTraits.some((updated: any) => updated.title === trait.title)
      );

      const removedExperienceTitles = match?.AssessmentReport?.experience
        .filter(
          (exp: any) =>
            !newAssessment.experience.some(
              (newExp: any) => newExp.title === exp.title
            )
        )
        .map((exp: any) => exp.title);

      const removedTraitsTitles = match?.AssessmentReport?.traits
        .filter(
          (trait: any) =>
            !newAssessment.traits.some(
              (newTrait: any) => newTrait.title === trait.title
            )
        )
        .map((trait: any) => trait.title);

      const updatedData = {
        ...match?.AssessmentReport,
        experience: [...updatedExperience, ...filteredExperience],
        traits: [...updatedTraits, ...filteredTraits],
        drivers:
          match.AssessmentReport.drivers.length > 0
            ? match.AssessmentReport.drivers
            : driversList,
      };

      removedExperienceTitles.forEach((title: any) => {
        const index = updatedData.experience.findIndex(
          (exp: any) => exp.title === title
        );
        if (index !== -1) {
          updatedData.experience.splice(index, 1);
        }
      });

      removedTraitsTitles.forEach((title: any) => {
        const index = updatedData.traits.findIndex(
          (trait: any) => trait.title === title
        );
        if (index !== -1) {
          updatedData.traits.splice(index, 1);
        }
      });

      setData(updatedData);
    } else if (jobData?.getJobDetails?.assessment) {
      const transformedExperience: ExperienceItem[] =
        jobData.getJobDetails.assessment.experience?.map((exp: any) => ({
          title: exp.title,
          level: '',
          comment: '',
        })) || [];

      const transformedTraits: ExperienceItem[] =
        jobData.getJobDetails.assessment.traits?.map((trait: any) => ({
          title: trait.title,
          level: '',
          comment: '',
        })) || [];

      const newAssessment = {
        experience: transformedExperience,
        traits: transformedTraits,
      };

      setData({
        ...match?.AssessmentReport,
        experience: newAssessment.experience || [],
        traits: newAssessment.traits || [],
        drivers: driversList,
      });
    }
  }, [match, jobData]);

  // useEffect(() => {
  //   if (match?.AssessmentReport) {
  //     setData(match?.AssessmentReport);
  //   } else if (jobData?.getJobDetails?.assessment) {
  //     //transforming data from job API
  //     const transformedExperience: ExperienceItem[] =
  //       jobData?.getJobDetails?.assessment.experience?.map((exp: any) => ({
  //         title: exp.title,
  //         level: '',
  //         comment: '',
  //       }));
  //     const transformedTraits: ExperienceItem[] =
  //       jobData?.getJobDetails?.assessment.traits?.map((exp: any) => ({
  //         title: exp.title,
  //         level: '',
  //         comment: '',
  //       }));
  //     setData({
  //       experience: transformedExperience,
  //       traits: transformedTraits,
  //       drivers: driversList,
  //     });
  //   }
  // }, [match, jobData]);

  return (
    <div style={Styles.root}>
      <SectionTitle
        title="Assessment tables"
        icon={AssessmentTableIcon}
        fontSize="16px"
        actionType="edit"
      />

      <Space size={16} direction="vertical" style={Styles.body}>
        {jobData?.getJobDetails?.assessment?.experience?.length !== 0 && (
          <Button
            block
            type="primary"
            size="large"
            style={{
              backgroundColor: '#990000',
              borderRadius: '8px',
              border: 'none',
              height: 44,
            }}
            onClick={() => handleClick('Experiences')}
          >
            Experiences
          </Button>
        )}
        {jobData?.getJobDetails?.assessment?.traits?.length !== 0 && (
          <Button
            block
            type="primary"
            size="large"
            style={{
              backgroundColor: '#2E4A79',
              borderRadius: '8px',
              border: 'none',
              height: 44,
            }}
            onClick={() => handleClick('Traits')}
          >
            Traits
          </Button>
        )}
        <Button
          block
          type="primary"
          size="large"
          style={{
            backgroundColor: '#418394',
            borderRadius: '8px',
            border: 'none',
            height: 44,
          }}
          onClick={() => handleClick('Drivers')}
        >
          Drivers
        </Button>
      </Space>

      <AssessmentTableModal
        open={openTable}
        setOpen={setOpenTable}
        view={view}
        setView={setView}
        candidateAssessment={data}
        matchId={match?._id}
      />
    </div>
  );
};

export default AssessmentTable;
