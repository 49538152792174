import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Drawer,
  Form,
  Tag,
  Select,
  Button,
  Input,
  DatePicker,
  Switch,
  Upload,
  message,
  Space,
} from 'antd';
import type { DatePickerProps } from 'antd';
import moment from 'moment';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { parse } from 'date-fns';
import {
  CloseOutlined,
  ConsoleSqlOutlined,
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { EditContext } from '../Context/EditContext';

import { update } from 'lodash';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import './edit.scss';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import RequiredField from 'helpers/RequiredField';
dayjs.extend(customParseFormat);

const { Option } = Select;
interface PropsType {
  candidate: any;
  open: boolean;
  handleClose?: any;
  onUpdate: () => void;
  data: any;
  index: any;
  candidateID: string;
}

interface InputPropsType {
  placeholder: string;
  value: string;
  label: string;
  name: string;
  required: boolean;
  type?: string;
  index?: any;
}

const Styles = {
  locationsWrapper: {
    marginTop: 20,
  },
  submitBtn: {
    width: 101,
    float: 'right',
    backgroundColor: '#BC9623',
    borderRadius: 20,
    marginTop: 20,
    border: 'none',
    color: 'white',
  },
  fieldWrapper: {
    marginBottom: 20,
  },
  fieldHalfWrapper: {
    width: '46%',
    marginBottom: 20,
  },
  fieldsContainer: {
    marginTop: 10,
  },
};

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const ExperienceEditDrawer: React.FC<PropsType> = ({
  candidate,
  open,
  handleClose,
  onUpdate,
  data,
  index,
  candidateID,
}) => {
  const [locations, setLocations] = useState<any>([]);
  const editContext = useContext<any>(EditContext);
  const { setData, setUpdatedData } = editContext;
  const [form] = Form.useForm();
  const [imgLoad, setImgLoad] = useState(false);
  const [current, setCurrent] = useState<any>(false);
  const locationRef = useRef<any>();
  const [startDateChanged, setStartDateChanged] = useState(false);
  const [endDateChanged, setEndDateChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    useGoogle({
      apiKey: 'AIzaSyACXA9qapZNvq51WpF69eIH908sX_efNMs',
      options: {
        types: ['geocode'],
      },
    });
  const [value, setValue] = useState([]);

  useEffect(() => {
    setCurrent('');
    setCurrent(false);
    data?.current !== '' && data?.current !== null && setCurrent(data?.current);
  }, [data?.current, candidate]);
  const onRemove: any = (e: any) => {
    setFileList([]);
    setImageUrl('');
  };

  useEffect(() => {
    setImageUrl('');
    setFileList([]);
    data?.logo &&
      data?.logo != null &&
      setFileList([
        {
          uid: '-1',
          name: 'xxx.png',
          status: 'done',
          url: data?.logo,
        },
      ]);
    data?.logo && data?.logo != null && setImageUrl(data?.logo);
  }, [data]);

  const onChange: UploadProps['onChange'] = (e: any) => {
    if (imgLoad) {
      setFileList(e.fileList);
    }
    if (e.file.status === 'done') {
      setImageUrl(e.file.response.secure_url);
      setIsUploading(false);
    }
  };

  const beforeUpload = (file: any) => {
    setImgLoad(true);
    const img = ['image/jpeg', 'image/png', 'image/jpg'];
    const isImg = img.includes(file.type);
    if (!isImg) {
      setImgLoad(false);
      message.error('You can only upload images file!');
      return Upload.LIST_IGNORE;
    }
    const isLt5M = file.size / 1024 / 1024 < 2;
    if (!isLt5M) {
      setImgLoad(false);
      message.error('Image must be smaller than 2MB!');
      return Upload.LIST_IGNORE;
    }
    return isImg && isLt5M;
  };
  useEffect(() => {
    form.resetFields();
  }, [data]);
  const onFinish = (values: any) => {
    if (values.current == undefined) {
      values.current = current;
    }

    if (!values?.location) {
      values.location = data?.location?.city + ',' + data?.location?.country;
    }

    values.startYear = values.startYear
      ? new Date(startDateChanged ? values.startYear : data?.startYear)
          .getFullYear()
          .toString()
      : data?.startYear;

    values.endYear = values.current
      ? null
      : values.endYear
      ? new Date(endDateChanged ? values.endYear : data?.endYear)
          .getFullYear()
          .toString()
      : data?.endYear;

    const locationObject = values?.location.split(',');
    const obj: any = {
      city: locationObject[0],
      country:
        locationObject.length > 1
          ? locationObject[locationObject.length - 1]
          : '',
      code: '0',
      coordinates: { lat: 0.0, lng: 0.0 },
    };
    values.location = obj;

    values.logo = imageUrl;
    const updatedExperience = editContext?.candidate.experiences;

    updatedExperience[index] = values;
    setUpdatedData({
      ...editContext?.updatedData,
      experiences: updatedExperience,
    });
    setData({
      ...editContext?.candidate,
      experiences: updatedExperience,
    });
    handleClose();
    // setFileList([]);
    // setImageUrl('');
  };

  const InputField = (Props: InputPropsType) => {
    const { placeholder, value, label, name, required, type } = Props;
    return (
      <div style={Styles.fieldWrapper}>
        <label style={{ marginBottom: 0, fontSize: 14, fontWeight: 400 }}>
          <RequiredField required={required} />
          {label}
        </label>
        <Form.Item
          name={name}
          rules={[{ required: required, message: 'This field is required!' }]}
          initialValue={value}
        >
          {type == 'textarea' ? (
            <TextArea
              style={{ borderRadius: 10 }}
              autoSize={{ minRows: 3, maxRows: 6 }}
              defaultValue={value}
            />
          ) : (
            <Input />
          )}
        </Form.Item>
      </div>
    );
  };

  const Fields = [
    {
      name: 'position',
      required: true,
      placeholder: 'Job title',
      label: 'Present or last job title',
    },
    {
      name: 'company',
      required: true,
      placeholder: 'Company',
      label: 'Present or last company name',
    },
  ];

  const uploadButton = (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {loading ? (
        <LoadingOutlined />
      ) : (
        <PlusOutlined style={{ fontSize: 18 }} />
      )}
      <div style={{ marginLeft: 10, fontSize: 16 }}>Upload logo</div>
    </div>
  );
  const val =
    data?.endYear &&
    data?.endYear != null &&
    data?.endYear != 'NaN' &&
    data?.current != true
      ? null
      : moment(data?.endYear, 'YYYY');
  const handleCloseDrawer = () => {
    handleClose();
    setCurrent(data?.current);
    data?.logo &&
      data?.logo != null &&
      setFileList([
        {
          uid: '-1',
          name: 'xxx.png',
          status: 'done',
          url: data?.logo,
        },
      ]);
    data?.logo && data?.logo != null && setImageUrl(data?.logo);

    form.resetFields();
  };
  return (
    <div>
      <Drawer
        title="Edit experience"
        placement={'right'}
        onClose={handleCloseDrawer}
        visible={open}
        extra={
          <Space onClick={handleCloseDrawer} style={{ cursor: 'pointer' }}>
            <CloseOutlined />
          </Space>
        }
      >
        <div className="experience-drawer-fields">
          <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            initialValues={{ remember: false }}
          >
            {Fields.map((field: any, index: any) => (
              <InputField
                key={index}
                required={field.required}
                placeholder={field.placeholder}
                value={data ? data[field.name] : ''}
                label={field.label}
                name={field.name}
              />
            ))}
            <div style={Styles.fieldWrapper}>
              <label style={{ marginBottom: 0, fontSize: 14, fontWeight: 400 }}>
                <RequiredField required={true} />
                Location
              </label>
              <Form.Item
                name="location"
                rules={[
                  {
                    required: true,
                    message: 'This field is required!',
                  },
                ]}
                initialValue={
                  data?.location?.country != null &&
                  data?.location?.country != '' &&
                  data?.location?.city != null &&
                  data?.location?.city != ''
                    ? data?.location?.city + ',' + data?.location?.country
                    : (data?.location?.country == null ||
                        data?.location?.country == '') &&
                      data?.location?.city
                    ? data?.location?.city
                    : ''
                }
              >
                <Select
                  showSearch
                  className="experience-location-selector"
                  ref={locationRef}
                  onSearch={(value: any) => {
                    getPlacePredictions({ input: value });
                    setValue(value);
                  }}
                  onChange={(value: any) => {
                    setValue(value);
                  }}
                  loading={isPlacePredictionsLoading}
                  filterOption={(input, option: any) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                >
                  {placePredictions &&
                    placePredictions.map((company: any) => {
                      return (
                        <Option
                          key={company.description}
                          value={company.description}
                        >
                          {company.description}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>

            <div
              style={{
                display: 'flex',
                // alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 15,
              }}
            >
              <div className="year-selector" style={Styles.fieldHalfWrapper}>
                <label
                  style={{ marginBottom: 0, fontSize: 14, fontWeight: 400 }}
                >
                  <RequiredField required={true} />
                  Start
                </label>
                <Form.Item
                  name="startYear"
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.reject('Start date is required!');
                        }
                        if (moment(value).isAfter(moment())) {
                          return Promise.reject(
                            'Start date cannot be a future date!'
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  initialValue={
                    data?.startYear &&
                    data?.startYear != null &&
                    data?.startYear != 'NaN' &&
                    data?.endYear != '' &&
                    moment(data?.startYear, 'YYYY')
                  }
                >
                  <DatePicker
                    format={'YYYY'}
                    picker={'year'}
                    onChange={() => setStartDateChanged(true)}
                  />
                </Form.Item>
              </div>
              <div className="year-selector" style={Styles.fieldHalfWrapper}>
                <label
                  style={{ marginBottom: 0, fontSize: 14, fontWeight: 400 }}
                >
                  <RequiredField required={true} />
                  End
                </label>
                <Form.Item
                  name="endYear"
                  dependencies={['current', 'startYear']}
                  // dependencies={['endYear']}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const endDate = moment(value);
                        const startDate = moment(getFieldValue('startYear'));
                        if (!value && !current) {
                          return Promise.reject('End date is required!');
                        }
                        if (endDate.isBefore(startDate) && !current) {
                          return Promise.reject(
                            'End date cannot be after the Start date!'
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  // rules={[
                  //   {
                  //     required: current == false,
                  //     message: 'End date is required',
                  //   },
                  // ]}
                  initialValue={
                    data?.endYear &&
                    data?.endYear != null &&
                    data?.endYear != '' &&
                    data?.endYear != 'NaN'
                      ? moment(data?.endYear, 'YYYY')
                      : null
                  }
                >
                  <DatePicker
                    disabled={current ? true : false}
                    onChange={() => setEndDateChanged(true)}
                    picker="year"
                    format="YYYY"
                  />
                </Form.Item>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
              }}
            >
              <div className="year-selector" style={Styles.fieldHalfWrapper}>
                <label
                  style={{ marginBottom: 0, fontSize: 14, fontWeight: 400 }}
                >
                  Currently working there
                </label>
                <Form.Item name="current">
                  <Switch
                    onChange={(e) => {
                      form.setFieldValue('endYear', null);
                      setCurrent(e);
                    }}
                    // checked={current}
                    defaultChecked={current}
                  />
                </Form.Item>
              </div>
              <div className="logo-selector" style={Styles.fieldHalfWrapper}>
                <Form.Item name="logo" initialValue={fileList}>
                  <Upload
                    style={{ width: '100%', height: 100, borderRadius: 10 }}
                    action={`${process.env.REACT_APP_BASE_REST_URL}/upload/candidates/${candidateID}`}
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    data={{
                      upload_preset: 'cqdbrmkw',
                    }}
                    onRemove={onRemove}
                    progress={{
                      strokeWidth: 2,
                      showInfo: false,
                    }}
                    accept="image/png, image/jpeg, image/jpg"
                    beforeUpload={beforeUpload}
                  >
                    {fileList.length == 0 && uploadButton}
                  </Upload>
                  {isUploading && (
                    <p style={{ textAlign: 'center', marginTop: 5 }}>
                      Please wait...
                    </p>
                  )}
                </Form.Item>
              </div>
            </div>
            <InputField
              key={'comments'}
              required={false}
              placeholder={''}
              value={data?.comments}
              label={'Comments'}
              name={'comments'}
              type={'textarea'}
            />
            <InputField
              key={'reasonForChange'}
              required={false}
              placeholder={''}
              value={data?.reasonForChange}
              label={'Reason for change'}
              name={'reasonForChange'}
              type={'textarea'}
            />

            <div>
              <Form.Item>
                <Button
                  style={{
                    width: 80,
                    float: 'right',
                    backgroundColor: '#BC9623',
                    borderRadius: 20,
                    marginTop: 0,
                    border: 'none',
                    color: 'white',
                  }}
                  htmlType="submit"
                >
                  Update
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Drawer>
    </div>
  );
};

export default ExperienceEditDrawer;
