import YearlyCompensation from '../components/YearlyCompensation';
import PersonalInfo from '../components/PersonalInfo';
import React from 'react';
import Education from '../components/Educations';
import Experience from '../components/Experiences';
import Languages from '../components/Languages';

interface Props {
  matchData: any;
  candidateData: any;
  jobData: any;
  isComposentation: any;
  isExperience: any;
  isEducation: any;
  showBenchmark: boolean;
  eduEndIndex: any;
  iconToShow: any;
  isLastPage: any;
  exp: any;
  edu: any;
  isSecondColumnExp: any;
  secondColExp: any;
  isThirdColumnExp: any;
  thirdColExp: any;
  isSecondColumnEdu: any;
  secondColEdu: any;
}

const ASSETS: any = {
  HIRED:
    'https://res.cloudinary.com/blonk-group/image/upload/v1711088339/blonk/icons/hire_1.svg',
  NEW: 'https://res.cloudinary.com/blonk-group/image/upload/v1711088325/blonk/icons/dislike.svg',
  NEWCANDIDATES:
    'https://res.cloudinary.com/blonk-group/image/upload/v1711088325/blonk/icons/dislike.svg',
  DISLIKE:
    'https://res.cloudinary.com/blonk-group/image/upload/v1711088322/blonk/icons/dislike_1.svg',
  LIKE: 'https://res.cloudinary.com/blonk-group/image/upload/v1711088314/blonk/icons/approve_1.svg', //update
  SELECTED:
    'https://res.cloudinary.com/blonk-group/image/upload/v1711088315/blonk/icons/approve_2.svg',
  REJECTED:
    'https://res.cloudinary.com/blonk-group/image/upload/v1711088319/blonk/icons/delete.svg',
  MATCHES:
    'https://res.cloudinary.com/blonk-group/image/upload/v1711088314/blonk/icons/approve_1.svg', // update
};

const Cdp: React.FC<any> = ({
  matchData,
  candidateData,
  jobData,
  isComposentation,
  isExperience,
  isEducation,
  showBenchmark = false,
  iconToShow,
  isLastPage,
  exp,
  edu,
  isSecondColumnExp,
  secondColExp,
  isThirdColumnExp,
  thirdColExp,
  isSecondColumnEdu,
  secondColEdu,
}: Props) => {
  return (
    <div
      style={{
        backgroundColor: '#111b2d',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '96vh',
        paddingTop: '7px',
        position: 'relative',
      }}
    >
      {/* pdf-body */}
      <div
        style={{
          backgroundColor: 'white',
          paddingLeft: '52px',
          paddingRight: '52px',
          paddingTop: '20px',
          paddingBottom: '20px',
          borderRadius: '90px',
          minWidth: '68vw',
          maxWidth: '95vw',
          maxHeight: '685px',
          overflow: 'hidden',
        }}
      >
        <div className="">
          <div className="" style={{ maxWidth: '60vw', margin: '0px auto' }}>
            <PersonalInfo
              match={matchData}
              showActions={true}
              candidate={candidateData}
              showBenchmark={showBenchmark}
              showActivation={true}
              loading={false}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '10px',
            marginTop: '-32px',
            justifyContent: 'center',
          }}
        >
          {isComposentation &&
            candidateData?.yearlyCompensation != null &&
            candidateData?.yearlyCompensation?.current != null &&
            (candidateData?.yearlyCompensation?.current?.total ||
              candidateData?.yearlyCompensation?.expected?.total) && (
              <div
                className=""
                style={{
                  marginLeft: '-0px',
                  width: '28%',
                  minWidth: '220px',
                }}
              >
                <YearlyCompensation
                  candidate={candidateData}
                  match={matchData}
                />
              </div>
            )}

          <div
            style={{
              display: 'flex',
              gap: '10px',
              flex: '',
              // width: '100%',
            }}
          >
            {/* Experience 1st column */}

            {candidateData?.experiences?.length > 0 && isExperience && (
              <div
                style={{ marginTop: 0, minWidth: '250px', maxWidth: '300px' }}
              >
                <Experience candidate={candidateData} exp={exp} />
              </div>
            )}

            {/* Experience 2nd column */}
            {isSecondColumnExp && (
              <div
                style={{
                  marginTop: 0,
                  flex: '',
                  flexDirection: 'column',
                  minWidth: '250px',
                  display: 'flex',
                  maxWidth: '300px',
                }}
              >
                <Experience
                  candidate={candidateData}
                  exp={secondColExp}
                  showTitle={false}
                />
              </div>
            )}
            {/* Experience 3nd column */}
            {isThirdColumnExp && (
              <div
                style={{
                  marginTop: 0,
                  flex: '',
                  flexDirection: 'column',
                  minWidth: '250px',
                  display: 'flex',
                  maxWidth: '300px',
                }}
              >
                <Experience
                  candidate={candidateData}
                  exp={thirdColExp}
                  showTitle={false}
                />
              </div>
            )}

            {/* Education 1st column */}
            {isEducation && (
              <div
                style={{
                  marginTop: 0,
                  flex: '',
                  flexDirection: 'column',
                  minWidth: '250px',
                  display: 'flex',
                  maxWidth: '300px',
                }}
              >
                <Education candidate={candidateData} edu={edu} />
              </div>
            )}

            {/* Education 2nd column */}
            {isSecondColumnEdu && (
              <div
                style={{
                  marginTop: 0,
                  flex: '',
                  flexDirection: 'column',
                  minWidth: '250px',
                  display: 'flex',
                  maxWidth: '300px',
                }}
              >
                <Education
                  candidate={candidateData}
                  edu={secondColEdu}
                  showTitle={false}
                />
              </div>
            )}

            {isLastPage && candidateData?.languages?.length > 0 && (
              <div
                className=""
                style={{ maxWidth: '270px', minWidth: '210px' }}
              >
                <Languages candidate={candidateData} />
              </div>
            )}
          </div>
          {/* )} */}
          {/* Footer  */}
          {/* <div
            className="d-flex justify-content-center align-items-center flex-column bottom-bar-wrapper hide-in-browser"
            style={{
              padding: '0px 85px',
              position: 'absolute',
              left: '0',
              bottom: '10px',
              width: '100%',
            }}
          >
            <div
              className="bottom-bar hide-in-browser"
              style={{
                boxSizing: 'border-box',
                backgroundColor: 'gold',
                height: '15px',
                width: '100%',
                marginBottom: '8px',
              }}
            ></div>
            <p className="job-title hide-in-browser" style={{ color: 'white' }}>
              Strictly confidential - {jobData?.company?.name} -{' '}
              {jobData?.title}
            </p>
          </div> */}
        </div>
      </div>
      {/* Top Right Icons */}
      {/* <span
        style={{
          position: 'absolute',
          top: '50px',
          right: '18px',
        }}
      >
        <img
          src={ASSETS[iconToShow?.toUpperCase()]}
          alt={iconToShow?.toUpperCase() ? iconToShow?.toUpperCase() : 'icon'}
          style={{ height: '70px', width: '70px' }}
        />
      </span> */}
      <div className="page-break"></div>
    </div>
  );
};

export default Cdp;
