import React, { useContext, useState } from 'react';
import { Drawer, Select, Input, Form, Button, Tag, Space } from 'antd';
import { EditContext } from '../../Profile/Context/EditContext';
import { useForm } from 'antd/lib/form/Form';
import { CloseOutlined } from '@ant-design/icons';
import { from } from 'apollo-boost';
import { log } from 'console';
const { TextArea } = Input;
interface PropsType {
  candidate: any;
  open: boolean;
  handleClose?: any;
}

interface InputPropsType {
  placeholder: string;
  value: string;
  label: string;
  name: string;
  required: boolean;
  textarea: boolean;
}

const Styles = {
  dpWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dpContainer: {
    height: 130,
    width: 130,
    backgroundSize: 'cover',
    marginTop: 15,
  },
  fieldWrapper: {
    marginBottom: 15,
  },
  fieldsContainer: {
    marginTop: 10,
  },
};

const InputField = (Props: InputPropsType) => {
  const { placeholder, value, label, name, required, textarea } = Props;

  return (
    <div style={Styles.fieldWrapper}>
      <label style={{ marginBottom: 0, fontSize: 13, fontWeight: 400 }}>
        <span style={{ color: '#990000' }}>{required && '*'}</span>
        {label}
      </label>
      <Form.Item
        name={name}
        rules={[{ required: required, message: 'This field is required!' }]}
        initialValue={value}
      >
        {!textarea ? (
          <Input placeholder={placeholder} />
        ) : (
          <TextArea
            style={{ borderRadius: 10 }}
            autoSize={{ minRows: 3 }}
            placeholder={placeholder}
          />
        )}
      </Form.Item>
    </div>
  );
};

const LanguagesDrawer: React.FC<PropsType> = ({
  candidate,
  open,
  handleClose,
}) => {
  const editContext = useContext(EditContext);
  const { setData, setUpdatedData }: any = editContext;
  const [form] = useForm();
  const [levelDisable, setLeveldisable] = useState(false);
  const [disableLevelValidationOnSubmit, setDisableLevelValidationOnSubmit] =
    useState(false);
  React.useEffect(() => {
    form.resetFields();
  }, [candidate]);
  const onFinish = (values: any) => {
    console.log(values);

    if (candidate?.languages?.length > 0) {
      const match = candidate.languages.find(
        (lang: any) => lang.language === values.language
      );
      if (match) {
        return;
      }
    }
    const languageComments = values.comments;
    delete values.comments;
    setUpdatedData({
      ...editContext?.updatedData,

      languages:
        editContext?.candidate.languages != null
          ? [...editContext?.candidate.languages, values]
          : [values],
      languageComments: languageComments,
    });
    setData({
      ...editContext?.candidate,
      languages:
        editContext?.candidate.languages != null
          ? [...editContext?.candidate.languages, values]
          : [values],
      languageComments: languageComments,
    });
    // handleClose();
    form.resetFields();
  };

  const removeSkill = (lang: string, level: string) => {
    console.log(lang + ',' + level);
    setUpdatedData({
      ...editContext?.updatedData,
      languages: editContext?.candidate.languages.filter(
        (language: any) =>
          language.language + ',' + language.level != lang + ',' + level
      ),
    });
    setData({
      ...editContext?.candidate,
      languages: editContext?.candidate.languages.filter(
        (language: any) =>
          language.language + ',' + language.level != lang + ',' + level
      ),
    });
  };

  const CustomTag = ({ title, lang, level }: any) => {
    return (
      <Tag
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        onClose={(e) => {
          e.preventDefault();
          removeSkill(lang, level);
        }}
        style={{
          border: 'none',
          marginRight: 10,
          marginBottom: 15,
          backgroundColor: 'rgba(18, 100, 121, 0.8)',
          borderRadius: 20,
          color: 'white',
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
        }}
        closable
      >
        {title}
      </Tag>
    );
  };
  const handleCloseDrawer = () => {
    form.resetFields();
    handleClose();
    setLeveldisable(false);
  };

  return (
    <div>
      <Drawer
        title="Candidate’s languages"
        placement={'right'}
        // closable={true}
        onClose={handleCloseDrawer}
        visible={open}
        extra={
          <Space onClick={handleCloseDrawer} style={{ cursor: 'pointer' }}>
            <CloseOutlined />
          </Space>
        }
      >
        <div style={{ marginBottom: 10 }}>
          {candidate?.languages &&
            candidate?.languages.map((language: any, index: any) => (
              <CustomTag
                key={index}
                lang={language.language}
                level={language.level}
                title={language.language + ', ' + language.level}
              />
            ))}
        </div>
        <Form
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
          scrollToFirstError={true}
          initialValues={{ remember: false }}
          form={form}
        >
          <div style={Styles.fieldWrapper}>
            <label style={{ marginBottom: 0, fontSize: 14, fontWeight: 400 }}>
              Add language
            </label>
            <Form.Item
              name="language"
              rules={[
                {
                  required: true,
                  validator: (_, value) => {
                    setLeveldisable(false);
                    if (!value) {
                      return Promise.reject('Language is required!');
                    }
                    if (
                      candidate?.languages
                        ?.map((lan: any) => lan?.language == value)
                        .includes(true)
                    ) {
                      setDisableLevelValidationOnSubmit(true);
                      setLeveldisable(true);
                      return Promise.reject('Already Selected!');
                    } else {
                      setDisableLevelValidationOnSubmit(false);
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Select>
                <Select.Option value="Arabic">Arabic</Select.Option>
                <Select.Option value="Bahasa">Bahasa</Select.Option>
                <Select.Option value="Chinese Mandarin">
                  Chinese Mandarin
                </Select.Option>
                <Select.Option value="Chinese Cantonese">
                  Chinese Cantonese
                </Select.Option>
                <Select.Option value="English">English</Select.Option>
                <Select.Option value="French">French</Select.Option>
                <Select.Option value="German">German</Select.Option>
                <Select.Option value="Hindi">Hindi</Select.Option>
                <Select.Option value="Italian">Italian</Select.Option>
                <Select.Option value="Japanese">Japanese</Select.Option>
                <Select.Option value="Korean">Korean</Select.Option>
                <Select.Option value="Portuguese">Portuguese</Select.Option>
                <Select.Option value="Russian">Russian</Select.Option>
                <Select.Option value="Spanish">Spanish</Select.Option>
                <Select.Option value="Thai">Thai</Select.Option>
                <Select.Option value="Urdu">Urdu</Select.Option>
                <Select.Option value="Ukrainian">Ukrainian</Select.Option>
              </Select>
            </Form.Item>
          </div>
          <div style={Styles.fieldWrapper}>
            <label style={{ marginBottom: 0, fontSize: 14, fontWeight: 400 }}>
              Indicate level
            </label>
            <Form.Item
              name="level"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Select disabled={levelDisable}>
                <Select.Option value="A1-Beginner">A1-Beginner</Select.Option>
                <Select.Option value="A2-Pre-intermediate">
                  A2-Pre-intermediate
                </Select.Option>
                <Select.Option value="B1-Intermediate">
                  B1-Intermediate
                </Select.Option>
                <Select.Option value="B2-Upper-intermediate">
                  B2-Upper-intermediate
                </Select.Option>
                <Select.Option value="C1-Advanced">C1-Advanced</Select.Option>
                <Select.Option value="C2-Mastery">C2-Mastery</Select.Option>
              </Select>
            </Form.Item>
          </div>

          {/* Comments */}
          <InputField
            textarea={true}
            required={false}
            placeholder={'Add comments'}
            value={candidate?.languageComments}
            label={'Comments'}
            name={'comments'}
          />
          {/* Submit Button */}
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            <Form.Item style={{ margin: 0 }}>
              {disableLevelValidationOnSubmit ? (
                <Button
                  htmlType="button"
                  style={{
                    height: 31,
                    width: 101,
                    backgroundColor: '#BC9623',
                    borderRadius: 40,
                    color: 'white',
                    border: 'none',
                  }}
                >
                  Save
                </Button>
              ) : (
                <Button
                  htmlType="submit"
                  style={{
                    height: 31,
                    width: 101,
                    backgroundColor: '#BC9623',
                    borderRadius: 40,
                    color: 'white',
                    border: 'none',
                  }}
                >
                  Save
                </Button>
              )}
              {/* <Button
                htmlType="submit"
                style={{
                  height: 31,
                  width: 101,
                  backgroundColor: '#BC9623',
                  borderRadius: 40,
                  color: 'white',
                  border: 'none',
                }}
              >
                Save
              </Button> */}
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

export default LanguagesDrawer;
