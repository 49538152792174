// eslint-disable-next-line @typescript-eslint/no-unused-expressions
import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import Highlighter from 'react-highlight-words';
// import PDFViewer from 'pdf-viewer-reactjs';
// import PDFViewer from 'pdf-viewer-reactjs'
import PopoverText from 'helpers/PopOver';

import {
  Input,
  Button,
  Popconfirm,
  Radio,
  Badge,
  Tooltip,
  Space,
  Table,
  Switch,
  notification,
  Modal,
  Tag,
  Card,
  Popover,
} from 'antd';
import {
  SnippetsOutlined,
  CloseCircleFilled,
  DeleteOutlined,
  SearchOutlined,
  UserOutlined,
  EditOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { generateMatches } from '../../../../../faker/matches';
import {
  useActivateCandidateMutation,
  useUntagCandidateMutation,
} from '../../../../../graphql/generated/graphql';
import moment from 'moment';
import './MatchesTable.module.scss';
import { decrypt } from 'helpers/crypto';

import { useSelector, useDispatch } from 'react-redux';
import { ColumnsType } from 'antd/es/table';
import { JobsState } from 'store/jobs/types';
import { StoreActiveJobs } from 'store/jobs/actions';
import confirm from 'antd/lib/modal/confirm';
import { Status } from 'containers/BackOffice/Candidates/Profile/PersonalInfo/Index';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  matchs: any;
  loadingMatches: boolean;
  jobId: string;
}

type ConfirmUntagProps = {
  tagId: string;
  candidateId: string;
  tagName: string;
};

interface Matches {
  key: number;
  id: string;
  photo: string;
  fname: string;
  lname: string;
  link: JSX.Element;
  title: string;
  email: string;
  summary: string;
  location: string;
  status: string;
  recruiterScore: string;
  candidateScore: string;
  fields: string;
  jobCount: number;
  companyCount: number;
  createdAt: string;
}
const MatchesTable: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();

  const { url } = useRouteMatch();
  const propsMatchesData = props.matchs;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [fakeData, setFakeData] = useState<any>({
    matchesRecord: [],
  });
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalValue, setModalValue] = useState('');
  const [matchesData, setMatchesData] = useState([]);
  const [untagCandidate] = useUntagCandidateMutation();
  const jobs = useSelector((state: JobsState) => state.jobs?.activeJobs);
  const [addActivateCandidateMutation, { loading }] =
    useActivateCandidateMutation();

  const showNotification = (message: string) => {
    const args = {
      message: message,
      duration: 10,
      icon: <CheckCircleOutlined style={{ color: 'green' }} />,
    };
    notification.open(args);
  };

  const updateCandidatesList = (propss: ConfirmUntagProps) => {
    const { tagId, candidateId } = propss;
    console.log(propsMatchesData);
    const temp: any = propsMatchesData.map((m: any) => {
      if (m?.candidateListing?._id === candidateId) {
        return {
          ...m,
          candidateListing: {
            ...m.candidateListing,
            tags: m.candidateListing.tags.filter(
              (tag: any) => tag._id !== tagId
            ),
          },
        };
      }
      return m;
    });
    const formatedData = formatMatchs(temp);
    setMatchesData(formatedData);
  };

  const handleUnTagCandidate = (candidateId: any, tagId: any, tagName: any) => {
    untagCandidate({
      variables: {
        tagId: tagId,
        candidateId: candidateId,
      },
    }).then((res) => {
      updateCandidatesList({
        tagId: tagId,
        candidateId: candidateId,
        tagName: tagName,
      });
      showNotification('Un-tagged successfully!');
    });
  };

  const confirmUnTagCandidate = (props: ConfirmUntagProps) => {
    const { candidateId, tagId, tagName } = props;
    confirm({
      title: (
        <p>
          Are you sure you want to remove the following tag:
          <p>{`"${tagName}"`}</p>
        </p>
      ),
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleUnTagCandidate(candidateId, tagId, tagName);
      },
      // onCancel() {},
    });
  };

  const Tags = (candidateId: any, tags: any[]) => {
    const AllTags = (tags: any[]) => {
      return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {tags.map((tag, index) => {
            return (
              <Tag
                style={{ borderRadius: 20, marginTop: 5 }}
                color={tag.color}
                key={index}
              >
                {tag.name}tags
                <CloseOutlined
                  style={{ marginLeft: 5 }}
                  onClick={() =>
                    confirmUnTagCandidate({
                      candidateId: candidateId,
                      tagId: tag._id,
                      tagName: tag.name,
                    })
                  }
                />
              </Tag>
            );
          })}
        </div>
      );
    };

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {tags.map((tag, index) => {
          return (
            index < 3 && (
              <Tag
                style={{ borderRadius: 20, marginTop: 5 }}
                color={tag.color}
                key={index}
              >
                {tag.name}
                <CloseOutlined
                  style={{ marginLeft: 5 }}
                  onClick={() =>
                    confirmUnTagCandidate({
                      candidateId: candidateId,
                      tagId: tag._id,
                      tagName: tag.name,
                    })
                  }
                />
              </Tag>
            )
          );
        })}
        {tags.length > 3 && (
          <Popover
            placement="leftBottom"
            content={() => AllTags(tags)}
            title="Tags"
            trigger="click"
          >
            <Tag
              style={{
                color: '#012653',
                borderColor: '#012653',
                borderRadius: 20,
                marginTop: 5,
                cursor: 'pointer',
                backgroundColor: 'transparent',
              }}
            >
              See more
            </Tag>
          </Popover>
        )}
      </div>
    );
  };

  const formatMatchs: any = (data: any = null) => {
    const matchsList = data ? [...data] : [...props.matchs];
    const temp = matchsList.map((match) => {
      return {
        key: match._id,
        id: match._id,
        candidateScore:
          match?.matchingAlgoScores?.length == 1
            ? match?.matchingAlgoScores[0]?.candidateScore.toFixed(2)
            : 'NAN',
        jobScore:
          match?.matchingAlgoScores?.length == 1
            ? match?.matchingAlgoScores[0]?.jobScore.toFixed(2)
            : 'NAN',
        activationScore: match.activationScore,
        fname: match.candidateListing.firstName,
        lname: match.candidateListing.lastName,
        company: match.candidateListing.company,
        tags: match.candidateListing?.tags
          ? Tags(match.candidateListing?._id, match.candidateListing?.tags)
          : '',
        firstNameLink: (
          <Link
            to={{
              pathname: `${url}/candidates/${match._id}`,
              state: {
                candidateArray: matchsList,
                candidateId: match._id,
                fromURL: `${url}`,
              },
            }}
          >
            {match.candidateListing.firstName}
          </Link>
        ),
        lastNameLink: (
          <Link
            to={{
              pathname: `${url}/candidates/${match._id}`,
              state: {
                candidateArray: matchsList,
                candidateId: match._id,
                fromURL: `${url}`,
              },
            }}
          >
            {match.candidateListing.lastName}
          </Link>
        ),
        title: match.candidateListing.position,
        email: match.candidateListing.user.email,
        emailLink: (
          <Link
            to={{
              pathname: `${url}/candidates/${match._id}`,
              state: {
                candidateArray: matchsList,
                candidateId: match._id,
                fromURL: `${url}`,
              },
            }}
          >
            {match?.candidateListing?.user?.email}
          </Link>
        ),
        location: match.candidateListing.location,
        // status: match.candidateListing.isProfileCompleted
        //   ? 'complete'
        //   : 'incomplete',
        status: match.candidateAction,
        media: match.media,
        resume: match.candidateListing.resume,
        createdAt: moment(parseInt(match.createdAt)).format('MMMM DD,  YYYY'),
        match: match,
      };
    });
    return temp;
  };

  let searchInput: any;

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              handleReset(clearFilters, selectedKeys, confirm, dataIndex)
            }
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text: any, record: any) => {
      if (searchedColumn === dataIndex) {
        const textHighlight =
          searchedColumn === 'fname' ||
          searchedColumn === 'lname' ||
          searchedColumn === 'email'
            ? text?.props?.children
            : text;

        // Wrap the text with a link if the dataIndex is 'firstNameLink'
        if (
          dataIndex === 'fname' ||
          dataIndex === 'lname' ||
          dataIndex ||
          'email'
        ) {
          return (
            <Link
              to={{
                pathname: `${url}/candidates/${record?.id}`,
                state: {
                  candidateArray: [...props.matchs],
                  candidateId: record._id,
                  fromURL: `${url}`,
                },
              }}
            >
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={textHighlight ? textHighlight.toString() : ''}
              />
            </Link>
          );
        } else {
          return (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={textHighlight ? textHighlight.toString() : ''}
            />
          );
        }
      } else {
        return text;
      }
    },
  });

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (
    clearFilters: any,
    selectedKeys: any,
    confirm: any,
    dataIndex: any
  ) => {
    clearFilters();
    setSearchText('');
    confirm();
    setSearchedColumn(dataIndex);
  };

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const actionsHandler = (id: any) => {
    return (
      <Space>
        <Popconfirm
          title="Are you sure delete this recruiter?"
          okText="Yes"
          cancelText="No"
        >
          <Button
            className="border-burgundy"
            shape="circle"
            icon={<DeleteOutlined className="text-burgundy" />}
          />
        </Popconfirm>
      </Space>
    );
  };

  const resumeHandler = (resume: any, status: any) => {
    if (resume) {
      return (
        <Button
          className="ant-btn-teal"
          shape="circle"
          onClick={() => showModal(resume)}
        >
          <SnippetsOutlined />
        </Button>
      );
    } else {
      return <CloseCircleFilled className="text-light font-30" />;
    }
  };

  const scoreHandler = (candidateScore: any, jobScore: any) => {
    return (
      <>
        <Tooltip title="Job score">
          <Badge showZero count={jobScore} className="badge-count left-badge" />
        </Tooltip>
        <Tooltip title="Candidate score">
          <Badge
            showZero
            count={candidateScore}
            className="badge-count right-badge"
          />
        </Tooltip>
      </>
    );
  };
  const renderCountries = (location: any, row: any) => {
    const country = location ? location[0]?.country : '';
    return (
      <>
        {location && location.length == 1 && location[0].country ? (
          <PopoverText country={country} isTag={false} />
        ) : (
          'no location'
        )}
      </>
    );
  };

  const updateJobsCandidate = (id: any, value: any) => {
    const jobId = props?.jobId;
    //updating newCandidate in list ofJobs
    const jobToUpdate = jobs?.map((job: any) => {
      if (job?._id === jobId) {
        job.newCandidates.map((candidate: any) => {
          if (candidate._id === id) {
            candidate.activationScore = value;
          }
          return candidate;
        });
      }
      return job;
    });

    ([...props?.matchs] || []).map((can: any) => {
      if (can?._id === id) {
        can.activationScore = value;
      }
      return can;
    });

    dispatch(StoreActiveJobs(jobToUpdate));
  };

  const onCandidateActivationHandler = (_id: any, value: any) => {
    // Call the activation API and update the score on the matches table
    addActivateCandidateMutation({
      variables: {
        matchId: _id,
        activationScore: parseInt(value),
      },
    })
      .then(({ data }) => {
        if (data?.activateCandidate == null) {
          notification['error']({
            message: 'Unable to activate candidate',
            description: <div>{'Something went wrong. Please try again'}</div>,
            duration: 3,
            onClose: () => setHasError(false),
          });
          return;
        }

        if (value == 2 || value == '2') {
          notification['success']({
            message: 'Candidate Activated successfully.',
            // description: `Candidate has been activated.`,
          });
        }
        try {
          updateJobsCandidate(_id, value);
        } catch (e) {
          console.log(e);
        }
      })
      .catch((err) => {
        const errors = err?.graphQLErrors?.map((el: any) => el.message);
        notification['error']({
          message: 'Unable to activate a candidate',
          description: errors?.map((er: any) => <div key={er}>{er}</div>),
          duration: 3,
          onClose: () => setHasError(false),
        });
      });
  };

  const activationStatusHandler = (
    activationScore: any,
    row: any,
    index: any
  ) => {
    return (
      <>
        <Radio.Group
          defaultValue={activationScore !== null ? activationScore : null}
          onChange={({ target }) =>
            onCandidateActivationHandler(row.id, target.value)
          }
          size="small"
          buttonStyle="solid"
        >
          <Radio.Button value={-1} style={{ fontSize: '12px' }}>
            -1
          </Radio.Button>
          <Radio.Button value={0} style={{ fontSize: '12px' }}>
            0
          </Radio.Button>
          <Radio.Button value={1} style={{ fontSize: '12px' }}>
            1
          </Radio.Button>
          <Radio.Button value={2} style={{ fontSize: '12px' }}>
            2
          </Radio.Button>
        </Radio.Group>
        {/* <Switch checked={activationScore !== null ? true : false} /> */}
      </>
    );
  };

  const columns: ColumnsType<Matches> = [
    {
      title: 'First name',
      dataIndex: 'firstNameLink',
      key: 'firstNameLink',
      // fixed: 'left',
      showSorterTooltip: false,
      width: 140,
      sorter: (a: any, b: any) => a?.fname?.localeCompare(b?.fname),
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'ascend',
      ...getColumnSearchProps('fname'),
      render: (val: any) => {
        return <PopoverText country={val} isTag={false} event="hover" />;
      },
    },
    {
      title: 'Last name',
      dataIndex: 'lastNameLink',
      key: 'lastNameLink',
      // fixed: 'left',
      width: 140,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a?.lname?.localeCompare(b?.lname),
      sortDirections: ['ascend', 'descend', 'ascend'],
      ...getColumnSearchProps('lname'),
      render: (val: any) => {
        return <PopoverText country={val} isTag={false} event="hover" />;
      },
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      width: 140,
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a?.company?.localeCompare(b?.company),
      render: (val: any) => {
        return <PopoverText country={val} isTag={false} />;
      },
      // ...getColumnSearchProps('company'),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 140,
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a?.title?.localeCompare(b?.title),
      render: (val: any) => {
        return <PopoverText country={val} isTag={false} />;
      },
      // ...getColumnSearchProps('title'),
    },
    {
      title: 'Email',
      dataIndex: 'emailLink',
      key: 'emailLink',
      width: 140,
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a?.email?.localeCompare(b?.email),
      render: (val: any) => {
        return <PopoverText country={val} isTag={false} event="hover" />;
      },
      // ...getColumnSearchProps('email'),
    },
    {
      title: 'Applied on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 140,
      // defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'ascend',
      showSorterTooltip: false,
      sorter: (a: any, b: any) =>
        moment(b?.createdAt).valueOf() - moment(a?.createdAt).valueOf(),
      render: (createdAt, record: any) => (
        <>{moment(createdAt).format('DD-MM-YYYY')}</>
      ),
      // ...getColumnSearchProps('createdAt'),
    },
    {
      title: 'Source',
      dataIndex: 'media',
      key: '_id',
      width: 140,
      render: (media: any, row: any) => (
        <span key={row._id}>{decrypt(media)}</span>
      ),
    },
    // {
    //   title: 'Score',
    //   dataIndex: 'candidateScore',
    //   key: 'candidateScore',
    //   render: (candidateScore, row: any) =>
    //     scoreHandler(candidateScore, row.jobScore),
    // },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: 140,
      sorter: (a: any, b: any) => {
        let countryA = a?.location ? a?.location[0]?.country : '';
        let countryB = b?.location ? b?.location[0]?.country : '';
        countryA = countryA?.trim();
        countryB = countryB?.trim();
        return countryA?.localeCompare(countryB);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
      render: (location, row) => renderCountries(location, row),
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      width: 282,
      // defaultSortOrder: 'ascend',
      // render: (tags, record: any) => (
      //   <div>{tags?.map((tag: any) => {
      //     return tag?.name
      //   })}</div>
      // ),
      // ...getColumnSearchProps('createdAt'),
    },
    // {
    //   title: 'Profile status',
    //   dataIndex: 'status',
    //   key: 'status',
    //   render: (status) => (
    //     <>
    //       {
    //         <span
    //           className={`font-10 px-2 m-2 badge badge-${status} badge-teal text-white`}
    //           key={status}
    //         >
    //           {status}
    //         </span>
    //       }
    //     </>
    //   ),
    // },
    // {
    //   title: 'Resume',
    //   dataIndex: 'resume',
    //   key: 'resume',
    //   width: 80,
    //   align: 'center',
    //   render: (resume, row) => resumeHandler(resume, row.status),
    // },
    // {
    //   title: 'Action',
    //   key: 'id',
    //   width: 80,
    //   align: 'center',
    //   render: actionsHandler,
    // },
    {
      title: 'Activation',
      dataIndex: 'activationScore',
      key: 'id',
      fixed: 'right',
      width: 120,
      render: activationStatusHandler,
      sorter: (a: any, b: any) => {
        const order = [2, 1, 0, -1];

        const getOrderIndex = (score: any) => {
          if (score === null || score === undefined) return order.length;
          const index = order.indexOf(score);
          return index === -1 ? order.length : index;
        };

        const indexA = getOrderIndex(a.activationScore);
        const indexB = getOrderIndex(b.activationScore);

        return indexA - indexB;
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'id',
      fixed: 'right',
      width: 80,
      render: (match: any, r: any) => {
        return (
          <div>
            <Status match={r?.match} isInTable={true} />
          </div>
        );
      },
    },
  ];

  const showModal = (resume: any) => {
    setModalValue(resume);
  };

  const handleOk = () => {
    setModalValue('');
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setModalValue('');
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (modalValue) {
      setIsModalVisible(true);
    }
  }, [modalValue]);

  useEffect(() => {
    const data = formatMatchs();
    setMatchesData(data);
  }, [props.matchs]);

  return (
    <>
      <span className="preselected-candidates-table-parent">
        <Table<Matches>
          className="table-striped"
          columns={columns}
          dataSource={matchesData}
          loading={props.loadingMatches}
          size="small"
          pagination={{
            defaultPageSize: 10,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
          }}
          scroll={{ x: 1300 }}
        />
        <Modal
          title="Candidate's CV"
          width={'70%'}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          {/* <PDFViewer
              scale={1.5}
              document={{
                url: modalValue,
                }}
                /> */}
          {/* <PDFViewer
            document={{
                url: modalValue,
            }}
            /> */}
          {/* <div style={{overflow: "scroll", margin: "0px auto"}}>
            <Document
              loading={"Please wait!"}
              file={{url: modalValue}}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={console.error}
            >
              <Page pageNumber={pageNumber} 
              scale={1.25} 
              />
            </Document>
            <p>Page {pageNumber} of {numPages}</p>
          </div> */}
          {/* <Card className="card m-b-30"> */}
          <div style={{ minHeight: '50vh' }}>
            <iframe
              className="card-img-bottom img-fluid"
              src={modalValue}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              width="100%"
              height="600"
              allowFullScreen={true}
            ></iframe>
          </div>
          {/* </Card> */}
        </Modal>
      </span>
    </>
  );
};

export default MatchesTable;
