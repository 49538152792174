import { EducationIcon } from 'assets/icons/DetailedProfile';
import { EducationDrawer, EducationEditDrawer } from '../../Edit';
import React, { useContext, useEffect, useState } from 'react';
import { EditContext } from '../../Context/EditContext';
import { SectionTitle } from '../../Shared';
import { SingleEducation, EducationComment } from './Index';
import { log } from 'console';

interface PropsType {
  candidate: any;
}

const Styles = {
  root: {
    padding: 15,
    // border: 'solid 1px #BC9623',
    boxShadow: '0px 0px 4px #00000040 ',
    borderRadius: 10,
    paddingBottom: 5,
  },
  body: {
    // paddingLeft: 24,
    marginTop: 20,
  },
};

const Education: React.FC<PropsType> = React.memo(({ candidate }) => {
  const editContext = useContext(EditContext);
  const { edit }: any = editContext;
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [editIndex, setEditIndex] = useState(null);

  const handleEdit = () => {
    setOpen(true);
  };

  const handleEditData = (id: any) => {
    setEditData(candidate?.education?.filter((edu: any) => id === edu._id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (candidate && candidate.education) {
      const sortedEducation = candidate.education.sort((a: any, b: any) => {
        if (a.current && !b.current) {
          return -1; // a comes before b
        } else if (!a.current && b.current) {
          return 1; // b comes before a
        } else if (a.current && b.current) {
          return b.endYear - a.endYear; // sort by descending endYear
        } else {
          return b.endYear - a.endYear; // sort by descending endYear
        }
      });
      // Update the education array with the sorted data
      // Assuming you have a state or a way to update candidate's education
      // Example: setCandidateEducation(sortedEducation);
    }
  }, [candidate]);
  console.log(candidate?.education);

  return (
    <>
      <EducationDrawer
        onUpdate={handleClose}
        open={open}
        candidate={candidate}
        handleClose={handleClose}
      />
      {(candidate?.education?.length > 0 || edit) && (
        <div style={Styles.root}>
          <SectionTitle
            title="Education"
            icon={EducationIcon}
            actionHandler={handleEdit}
            fontSize="16px"
            fontWeight={'600'}
            actionType="add"
            actionText="Add education"
          />
          <div style={Styles.body}>
            {candidate?.education &&
              candidate?.education?.map((education: any, index: number) => {
                return (
                  <div key={index}>
                    <SingleEducation
                      index={index}
                      onEdit={handleEditData}
                      education={education}
                      comments={candidate?.educationComment}
                      candidateID={candidate?._id}
                    />
                  </div>
                );
              })}
            {candidate?.educationComment && (
              <EducationComment comment={candidate?.educationComment} />
            )}
          </div>
        </div>
      )}
    </>
  );
});

export default Education;
