import React, { useState } from 'react';
import './AssessmentTableStyles.scss';
import { CompetenciesIcon } from 'assets/icons/DetailedProfile';
import { Button, Card, Divider, Space, Typography } from 'antd';
import AssessmentTableModal from './AssessmentTableModal';
const { Title } = Typography;

interface PropsType {
  assessmentData: any;
}

const Styles = {
  root: {
    padding: '12px 16px',
    boxShadow: '0px 0px 4px #00000040 ',
    borderRadius: 10,
  },
  body: {
    marginTop: 16,
    display: 'flex',
  },
};

const AssessmentTable: React.FC<PropsType> = ({ assessmentData }) => {
  const [openTable, setOpenTable] = useState(false);
  const [view, setView] = useState('');

  const handleClick = (ViewOption: string) => {
    setView(ViewOption);
    setOpenTable(true);
  };

  return (
    <Card className="mb-2 displayCards">
      <Title className="mt-0 text-primary" style={{ fontSize: '20px' }}>
        <img
          src={CompetenciesIcon}
          alt="Competencies icon"
          style={{ marginRight: '10px' }}
        />
        Competences target profile
      </Title>
      <Divider style={{ margin: '10px 0px' }} />
      <Space size={16} direction="vertical" style={Styles.body}>
        {assessmentData?.experience?.length !== 0 && (
          <Button
            block
            type="primary"
            size="large"
            style={{
              backgroundColor: '#990000',
              borderRadius: '8px',
              border: 'none',
              height: 44,
              fontSize: '16px',
            }}
            onClick={() => handleClick('experiences')}
          >
            Experiences
          </Button>
        )}
        {assessmentData?.traits?.length !== 0 && (
          <Button
            block
            type="primary"
            size="large"
            style={{
              backgroundColor: '#2E4A79',
              borderRadius: '8px',
              border: 'none',
              height: 44,
              fontSize: '16px',
            }}
            onClick={() => handleClick('traits')}
          >
            Traits
          </Button>
        )}
      </Space>

      <AssessmentTableModal
        open={openTable}
        setOpen={setOpenTable}
        view={view}
        setView={setView}
        assessment={assessmentData}
      />
    </Card>
  );
};

export default AssessmentTable;
