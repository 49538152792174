import React, { useEffect, useState } from 'react';
import {
  Attachment,
  Education,
  Experience,
  Languages,
  PersonalDetails,
  PersonalInfo,
  ProfessionalIntrests,
  Resume,
  TargetLocations,
  YearlyCompensation,
} from '../Profile';
import { useParams } from 'react-router-dom';
import {
  useGetCandidateQuery,
  useUpdateCandidateMutation,
} from '../../../../graphql/generated/graphql';
import { Row, Col, message } from 'antd';
import { Summary } from '../Profile/Summary/Index';
import Comments from '../Profile/RecruiterComments/Comments';
import NextSteps from '../Profile/RecruiterComments/NextSteps';
import { EditContext } from '../Profile/Context/EditContext';
import { PageHeader, TopNavigation } from '../Profile/Shared';
import CandidateTopNavigation from '../Profile/Shared/CandidateTopNavigation';
import { useLocation } from 'react-router-dom';
import Myobjective from '../Profile/Motivations/Myobjective';
import Mytarget from '../Profile/Motivations/Mytarget';
import Inspirecompanies from '../Profile/Motivations/Inspirecompanies';
import AutoFill from '../Profile/AutoFill';

const ViewCandidateProfile: React.FC<any> = () => {
  // const { matchId, jobId } = useParams<{ matchId: string; jobId: string }>();
  const [candidateIds, setCandidateIds] = useState<string[]>([]);
  const editContext = React.useContext(EditContext);
  const location: any = useLocation();
  // console.log('location.state?.candidateIDs', location.state?.candidateIDs);

  const { setData, cancelEditProfile, updatedData, setActivityDatas }: any =
    editContext;
  const { id } = useParams<{ id: string }>();
  const [updateCandidateMutation] = useUpdateCandidateMutation();
  const [candidateData, setCandidateData] = useState<any | null | undefined>(
    null
  );
  const { data, loading, error, refetch } = useGetCandidateQuery({
    variables: {
      candidateId: id,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data && data?.getCandidate) {
      setData(data?.getCandidate);
    }
    console.log(updatedData);
  }, [data]);

  useEffect(() => {
    setCandidateData(editContext?.candidate);
  }, [editContext?.candidate]);
  useEffect(() => {
    const obj = {
      candidateIDs: location?.state?.candidateIDs,
      candidateId: location?.state?.candidateId,
      fromURL: location?.pathname,
    };
    setActivityDatas(obj);
  }, []);
  const UpdateChanges = () => {
    const key = 'updatable';
    message.loading({ content: 'Updating profile...', key });
    console.log(updatedData);
    let updatedObj;
    if (updatedData.currentLocation) {
      const {
        currentLocation: { city, country },
        ...rest
      } = updatedData;

      updatedObj = {
        city,
        country,
        ...rest,
      };
    } else {
      updatedObj = updatedData;
    }
    updateCandidateMutation({
      variables: { candidateId: id, ...updatedObj },
    })
      .then((res) => {
        message.success({
          content: 'Profile updated successfuly!',
          key,
          duration: 5,
        });
        cancelEditProfile();
      })
      .catch((err) => {
        message.error({ content: 'Something went wront!', key, duration: 5 });
      });
  };

  const Motivation =
    (candidateData?.targetLocations?.length == 0 &&
      !candidateData?.personalInformation &&
      !candidateData?.yearlyCompensation?.currency &&
      candidateData?.inspiringCompanies?.length == 0 &&
      candidateData?.professionalInterests?.length == 0 &&
      candidateData?.objectives?.length == 0 &&
      candidateData?.sectors?.length == 0 &&
      !editContext?.edit) == false;

  const Background =
    (candidateData?.experiences?.length == 0 &&
      candidateData?.education?.length == 0 &&
      candidateData?.languages?.length == 0 &&
      !candidateData?.resume &&
      !candidateData?.coverLetter &&
      !editContext?.edit) == false;

  return (
    <div style={{ padding: 30, paddingTop: 4 }}>
      {/* Back, Edit , Save Cancel Button */}
      <PageHeader
        refetch={refetch}
        initialData={data?.getCandidate}
        handleSave={UpdateChanges}
        to={true}
      />
      {/* <AutoFill candidate={candidateData} /> */}
      {/* Main Body Content */}
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: 10,
          paddingLeft: 35,
          paddingRight: 35,
          paddingTop: 29,
          paddingBottom: 29,
        }}
      >
        {!loading && data?.getCandidate !== null && (
          <>
            <div style={{ marginTop: 0, marginBottom: 30 }}>
              <CandidateTopNavigation />
            </div>
            <Row
              gutter={22}
              style={{
                display: 'flex',
                alignItems: 'stretch',
                marginLeft: -10,
                marginRight: -130,
              }}
            >
              <Col span={22}>
                <PersonalInfo
                  showActions={true}
                  candidate={candidateData}
                  showBenchmark={false}
                  showActivation={false}
                  loading={loading}
                />
              </Col>
              {/* <Col span={17}>
                <Summary candidate={candidateData} isBenchmark={false} />
              </Col> */}
            </Row>
            <Row
              style={{ marginTop: 70 }}
              gutter={22}
              className={
                Motivation == false || Background == false
                  ? 'align_Content'
                  : ''
              }
            >
              {/* Motivation */}
              {
                // (candidateData?.targetLocations?.length == 0 &&
                //   !candidateData?.personalInformation &&
                //   !candidateData?.yearlyCompensation?.currency &&
                //   (candidateData?.yearlyCompensation?.current?.total?.length ==
                //     0 ||
                //     candidateData?.yearlyCompensation?.expected?.total?.length ==
                //       0) &&
                //   candidateData?.inspiringCompanies?.length == 0 &&
                //   candidateData?.professionalInterests?.length == 0 &&
                //   candidateData?.objectives?.length == 0 &&
                //   candidateData?.sectors?.length == 0 &&
                //   !editContext?.edit) == false &&
                (candidateData?.targetLocations?.length > 0 ||
                  candidateData?.professionalInterests?.length > 0 ||
                  candidateData?.objectives?.length > 0 ||
                  candidateData?.sectors?.length > 0 ||
                  candidateData?.inspiringCompanies?.length > 0 ||
                  (candidateData?.yearlyCompensation?.currency &&
                    (candidateData?.yearlyCompensation?.current?.total
                      ?.length ||
                      candidateData?.yearlyCompensation?.expected?.total
                        ?.length)) ||
                  candidateData?.personalInformation ||
                  editContext?.edit) && (
                  <Col span={Background == false ? 10 : 7}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 10,
                        fontSize: 18,
                        fontWeight: 500,
                        color: '#FFFFFF',
                        backgroundColor: '#2E4A79',
                        borderRadius: 10,
                        marginBottom: 30,
                      }}
                    >
                      <span>MOTIVATIONS</span>
                    </div>
                    <TargetLocations candidate={candidateData} />

                    <div style={{ marginTop: 20 }}>
                      <ProfessionalIntrests candidate={candidateData} />
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <Myobjective candidate={candidateData} />
                    </div>
                    <div
                      // ref={professionalInterestsRef}
                      style={{ marginTop: 20 }}
                    >
                      <Mytarget candidate={candidateData} />
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <Inspirecompanies candidate={candidateData} />
                    </div>

                    <div style={{ marginTop: 20 }}>
                      <YearlyCompensation
                        candidate={candidateData}
                        showSwitch={false}
                      />
                    </div>

                    <div style={{ marginTop: 20 }}>
                      <PersonalDetails candidate={candidateData} />
                    </div>
                  </Col>
                )
              }

              {/* Motivation */}

              {/* Background */}
              {(candidateData?.experiences?.length == 0 &&
                candidateData?.education?.length == 0 &&
                candidateData?.languages?.length == 0 &&
                !candidateData?.resume &&
                !candidateData?.coverLetter &&
                !editContext?.edit) == false && (
                <Col span={Motivation == false ? 10 : 9}>
                  {/* Background */}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 10,
                      fontSize: 18,
                      fontWeight: 500,
                      color: '#FFFFFF',
                      backgroundColor: '#BC9623',
                      borderRadius: 10,
                      marginBottom: 30,
                    }}
                  >
                    <span>BACKGROUND</span>
                  </div>
                  {/* Experience */}
                  <div style={{ marginTop: 20 }}>
                    <Experience candidate={candidateData} />
                  </div>
                  {/* Education */}
                  <div style={{ marginTop: 20 }}>
                    <Education candidate={candidateData} />
                  </div>
                  {/* Languages */}
                  <div style={{ marginTop: 20 }}>
                    <Languages candidate={candidateData} />
                  </div>
                  {/* Resume */}
                  <div style={{ marginTop: 20 }}>
                    <Resume candidate={candidateData} />
                  </div>
                  {/* Attachment */}
                  <div style={{ marginTop: 20 }}>
                    <Attachment candidate={candidateData} />
                  </div>
                </Col>
              )}
              {/* Background End */}

              {/* Recruiter's Comment */}
              {/* <Col span={8}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10, fontSize: 18, fontWeight: 500, color: '#2E4A79', backgroundColor: "rgba(26, 131, 140, 0.42)", borderRadius: 10, marginBottom: 30 }}>
                  <span>RECRUITERS’ COMMENTS</span>
                </div>

                <div style={{ marginTop: 20 }}>
                  <Comments candidate={candidateData} />
                </div>

                <div style={{ marginTop: 20 }}>
                  <NextSteps candidate={candidateData} />
                </div>

              </Col> */}
              {/* Recruiter's Comment End */}
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

export default ViewCandidateProfile;
