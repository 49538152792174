import React, { useContext, useState } from 'react';
import { Avatar, Button, Popover } from 'antd';
import { EditDelete } from '../../../Shared';
import { EditContext } from '../../../Context/EditContext';
import EditButton from '../../../EditIcon';
import DeleteButton from '../../../DeleteIcon';
import { EducationEditDrawer } from '../../../Edit';

interface PropsType {
  index: any;
  education: any;
  onEdit: (id: any) => any;
  comments: string;
  candidateID: string;
}

const Styles = {
  root: {
    borderLeft: 'solid 1.5px #2E4A79',
    paddingLeft: 25,
    display: 'inline-block',
    maxWidth: 400,
    flex: '85%',
  },
  wrapper: {
    display: 'flex',
    // alignItems: 'end',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  year: {
    fontStyle: 'italic',
    fontSize: 14,
    fontWeight: 400,
    marginTop: 0,
    marginBottom: 0,
    color: '#495057',
  },
  position: {
    fontWeight: 600,
    fontSize: 16,
    marginTop: 0,
    marginBottom: 0,
    color: '#495057',
  },
  company: {
    fontWeight: 600,
    fontSize: 14,
    marginTop: 0,
    marginBottom: 0,
    color: '#495057',
  },
  avatar: {
    backgroundColor: '#F5F7F9',
  },
  actionWrapper: {
    display: 'flex',
    // flexDirection:'column'
  },
};

const SingleEducation: React.FC<PropsType> = ({
  education,
  comments,
  index,
  candidateID,
}) => {
  const editContext: any = useContext(EditContext);
  const [open, setOpen] = useState(false);
  const { setData, setUpdatedData } = editContext;
  const [openDelete, setOpenDelete] = useState(false);
  const { edit } = editContext;

  const handleDelete = () => {
    setOpenDelete(false);
    setUpdatedData({
      ...editContext?.updatedData,
      education: editContext?.candidate.education.filter(
        (exp: any, ind: any) => ind != index
      ),
    });
    setData({
      ...editContext?.candidate,
      education: editContext?.candidate.education.filter(
        (exp: any, ind: any) => ind != index
      ),
    });
  };
  return (
    <>
      <EducationEditDrawer
        candidateID={candidateID}
        onUpdate={() => setOpen(false)}
        open={open}
        candidate={education}
        handleClose={() => setOpen(false)}
        data={education}
        index={index}
        comments={comments}
      />
      <div style={Styles.wrapper}>
        <div style={{ ...Styles.root, wordWrap: 'break-word' }}>
          <p style={Styles.year}>
            {education?.startYear && (education.endYear || education.current)
              ? education.startYear + '-'
              : education?.startYear}
            {education.current ? 'Current' : education.endYear}
          </p>
          <p style={Styles.position}>{education.degree}</p>
          <p style={Styles.company}>
            @{education.schoolName}{' '}
            <span style={{ fontSize: 14, fontWeight: 400 }}>
              {education?.location?.city && education?.location?.country
                ? '| ' +
                  education?.location?.city +
                  ', ' +
                  education?.location?.country
                : education?.location?.city &&
                  education?.location?.country == ''
                ? ' | ' + education?.location?.city
                : ''}
            </span>
            {/* {education.location != null && (
              <span>
                |{' '}
                <span style={{ fontSize: 14, fontWeight: 300 }}>
                  {education.location}
                </span>
              </span>
            )} */}
          </p>
        </div>
        <div
          style={{
            ...Styles.actionWrapper,
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          {edit && (
            <Popover
              content={
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button onClick={() => setOpenDelete(false)}>No</Button>
                  <Button type={'primary'} onClick={handleDelete}>
                    Yes
                  </Button>
                </div>
              }
              title={
                <h4 style={{ margin: 0, textAlign: 'center' }}>
                  Are you sure?
                </h4>
              }
              trigger="click"
              open={openDelete}
              onOpenChange={() => setOpenDelete(true)}
            >
              <EditDelete
                editHandler={() => setOpen(true)}
                deleteHandler={() => setOpenDelete(true)}
              />
            </Popover>
          )}
          <div style={{ flex: 1 }}></div>
          <Avatar
            style={{
              ...Styles.avatar,
              backgroundImage: `url(${education.logo})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              objectFit: 'cover',
            }}
            size={32}
          ></Avatar>
        </div>
      </div>
    </>
  );
};

export default SingleEducation;
