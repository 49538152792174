import { FlagIcon } from 'assets/icons/DetailedProfile';
import React, { useContext, useEffect, useState } from 'react';
import { SectionTitle } from '../../Shared';
import FileViewer from 'react-file-viewer';
import { EditContext } from '../../Context/EditContext';
import { UploadProps } from 'antd/es/upload/interface';
import './CoverLetter.scss';
import { message } from 'antd';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

interface PropsType {
  candidate: any;
}

const Styles = {
  root: {
    padding: 15,
    // border: 'solid 1px #BC9623',
    boxShadow: '0px 0px 4px #00000040 ',
    borderRadius: 10,
    paddingBottom: 5,
  },
  body: {
    // paddingLeft: 24,
    marginTop: 10,
  },
  fileWrapper: {
    height: 500,
    // border: 'solid 1px #ececec',
    marginBottom: 10,
  },
};

const Attachment: React.FC<PropsType> = ({ candidate }) => {
  function get_url_extension(url: string) {
    return url?.split(/[#?]/)[0]?.split('.')?.pop()?.trim();
  }
  const editContext = useContext(EditContext);
  const { edit }: any = editContext;
  const [fileAdded, setFileAdded] = useState(true);
  const { setData, setUpdatedData }: any = editContext;
  const [docs, setDocs] = useState<any>([{ uri: candidate?.coverLetter }]);

  const onUpdate: UploadProps['onChange'] = (e: any) => {
    // console.log(e);

    const isLt5M = e?.file?.size / 1024 / 1024 > 10;
    if (e.file.status === 'done') {
      if (!isLt5M) {
        setUpdatedData({
          ...editContext?.updatedData,
          coverLetter: e.file.response.secure_url,
        });
        setData({
          ...editContext?.candidate,
          coverLetter: e.file.response.secure_url,
        });
      } else {
        setFileAdded(false);
        message.error('Attachment must be smaller than 10MB!');
      }
    }
  };

  // useEffect(() => {
  //   setFileAdded(false);
  //   setTimeout(() => {
  //     setFileAdded(true);
  //   }, 1000);
  // }, [editContext?.candidate?.coverLetter]);
  useEffect(() => {
    setFileAdded(false);

    setTimeout(() => {
      setDocs([{ uri: editContext?.candidate?.coverLetter }]);
      setFileAdded(true);
    }, 1000);
  }, [editContext?.candidate]);
  // console.log(candidate?.coverLetter);

  return (
    <>
      {(candidate?.coverLetter || editContext?.edit) && (
        <div style={Styles.root} className="resume-preview">
          <SectionTitle
            fileLink={editContext?.candidate?.coverLetter}
            title="Attachment"
            fontSize="16px"
            fontWeight={'600'}
            actionType="uvd"
            updateAction={onUpdate}
            candidateId={candidate?._id}
          />
          {candidate?.coverLetter && (
            <div style={Styles.body} className="hidden-uploader">
              <div style={Styles.fileWrapper}>
                {editContext?.candidate?.coverLetter && fileAdded && (
                  // <FileViewer
                  //   fileType={get_url_extension(
                  //     editContext?.candidate?.coverLetter
                  //   )}
                  //   filePath={editContext?.candidate?.coverLetter}
                  //   errorComponent={() => <div>Error loading Document</div>}
                  //   onError={(e: any) => console.log({ e })}
                  // />
                  // <DocViewer
                  //   pluginRenderers={DocViewerRenderers}
                  //   documents={docs}
                  //   config={{
                  //     header: {
                  //       disableHeader: true,
                  //       disableFileName: true,
                  //       retainURLParams: true,
                  //     },
                  //   }}
                  //   style={{ height: 500 }}
                  // />
                  <iframe
                    // src="https://res.cloudinary.com/blonk-group/image/upload/v1611151174/users-cvs/buu4qxkwc8f6hns31dv4.pdf"
                    // src={candidate?.coverLetter}
                    src={
                      candidate?.coverLetter?.includes('.pdf')
                        ? candidate?.coverLetter +
                          '#toolbar=0&scrollbar=0&view=fitH&view=fitV'
                        : `https://docs.google.com/gview?url=${candidate?.coverLetter}&embedded=true`
                    }
                    scrolling="auto"
                    style={{
                      width: '100%',
                      height: '100%',
                      background: '#fffff',
                    }}
                    allowFullScreen={false}
                  ></iframe>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Attachment;
