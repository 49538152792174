import { FlagIcon } from 'assets/icons/DetailedProfile';
import React, { useContext, useEffect, useState } from 'react';
import { SectionTitle } from '../../Shared';
import FileViewer from 'react-file-viewer';
import { EditContext } from '../../Context/EditContext';
import { UploadProps } from 'antd/es/upload/interface';
import { message } from 'antd';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import './Resume.scss';
interface PropsType {
  candidate: any;
}

const Styles = {
  root: {
    padding: 15,
    // border: 'solid 1px #BC9623',
    boxShadow: '0px 0px 4px #00000040 ',
    borderRadius: 10,
    paddingBottom: 5,
  },
  body: {
    // paddingLeft: 24,
    marginTop: 10,
  },
  fileWrapper: {
    height: 600,
    border: 'solid 1px #ececec',
    marginBottom: 10,
  },
};

const Resume: React.FC<PropsType> = ({ candidate }) => {
  function get_url_extension(url: string) {
    return url?.split(/[#?]/)[0]?.split('.')?.pop()?.trim();
  }
  const editContext = useContext(EditContext);
  const { edit }: any = editContext;
  const [fileAdded, setFileAdded] = useState(true);
  const { setData, setUpdatedData }: any = editContext;
  const [docs, setDocs] = useState<any>([]);
  const onUpdate: UploadProps['onChange'] = (e: any) => {
    const isLt5M = e?.file?.size / 1024 / 1024 > 10;
    if (e.file.status === 'done') {
      if (!isLt5M) {
        setUpdatedData({
          ...editContext?.updatedData,
          resume: e.file.response.secure_url,
        });
        setData({
          ...editContext?.candidate,
          resume: e.file.response.secure_url,
        });
      } else {
        setFileAdded(false);
        message.error('Resume must be smaller than 10MB!');
      }
    }
  };
  useEffect(() => {
    setFileAdded(false);
    // console.log(editContext?.candidate?.resume);
    setTimeout(() => {
      setFileAdded(true);
    }, 1000);
  }, [editContext?.candidate?.resume]);
  return (
    <>
      {(candidate?.resume || editContext?.edit) && (
        <div style={Styles.root} className="resume-preview">
          <SectionTitle
            fileLink={editContext?.candidate?.resume}
            title="Resume"
            fontSize="16px"
            fontWeight={'600'}
            actionType="uvd"
            updateAction={onUpdate}
            candidateId={candidate?._id}
          />

          {candidate?.resume && (
            <div className="hidden-uploader" style={Styles.fileWrapper}>
              {editContext?.candidate?.resume && fileAdded && (
                <iframe
                  src={
                    candidate?.resume?.includes('.pdf')
                      ? candidate?.resume +
                        '#toolbar=0&scrollbar=0&view=fitH&view=fitV'
                      : `https://docs.google.com/gview?url=${candidate?.resume}&embedded=true`
                  }
                  style={{
                    width: '100%',
                    height: '100%',
                    background: '#fffff',
                  }}
                  allowFullScreen={false}
                ></iframe>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Resume;
