import React, { useState, useEffect, useContext } from 'react';
import { Button, Drawer, Form, Space } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { EditContext } from '../Context/EditContext';
import { Editor } from '@tinymce/tinymce-react';
import { useCandidateNextStepsMutation } from 'graphql/generated/graphql';
interface PropsType {
  candidate: any;
  open: boolean;
  handleClose?: any;
  matchStep: any;
}

const NextStepsDrawer: React.FC<PropsType> = ({
  candidate,
  open,
  handleClose,
  matchStep,
}) => {
  const [form] = Form.useForm();
  const editContext = useContext(EditContext);
  const { match, setMatch, setUpdatedData, setData }: any = editContext;
  const ref = React.useRef<any>();
  const [info, setInfo] = useState<any>(match?.nextSteps);
  useEffect(() => {
    if (open) {
      setInfo(match?.nextSteps);
    }
  }, [match, open]);

  const handleSubmit = () => {
    setMatch({
      ...editContext?.match,
      nextSteps: info,
    });

    setUpdatedData({
      ...editContext?.updatedData,
      personalInformation: info,
    });
    setData({
      ...editContext?.candidate,
      personalInformation: info,
    });
    handleClose();
  };
  useEffect(() => {
    console.log(match);
  }, [matchStep]);
  useEffect(() => {
    form.resetFields();
    // Reset the Editor component's value when the candidate or match is changed
    if (ref && ref.current && match) {
      ref.current.editor.setContent(match.nextSteps ?? '');
    }
  }, [candidate, match, form]);
  const handleCloseDrawer = () => {
    form.resetFields();
    handleClose();
  };
  return (
    <div>
      <Drawer
        title="Candidate’s next steps"
        placement={'right'}
        // closable={true}
        onClose={handleCloseDrawer}
        visible={open}
        extra={
          <Space onClick={handleCloseDrawer} style={{ cursor: 'pointer' }}>
            <CloseOutlined />
          </Space>
        }
      >
        <Form form={form}>
          <label
            style={{
              marginBottom: '7px',
              lineHeight: 1,
              fontSize: '16px',
              fontWeight: 400,
            }}
          >
            Next step
          </label>
          <div
            style={{ border: 'solid 1px gray', borderRadius: 10, padding: 5 }}
          >
            <Editor
              value={info}
              ref={ref}
              apiKey="bgow6d7d89zjmvftzkn8p8gzzhkzyd551egy09ik6j400toe"
              inline
              init={{
                minHeight: 400,
                menubar: false,
                placeholder: 'Next steps',
                plugins: [
                  'advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker',
                  'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
                  'save table contextmenu directionality emoticons template paste textcolor',
                ],
                inline: false,
                toolbar:
                  'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons',
                smart_paste: false,
                force_br_newlines: true,
                force_p_newlines: false,
                forced_root_block: 'div',
              }}
              outputFormat="html"
              onEditorChange={(newValue, editor) => setInfo(newValue)}
            />
          </div>
          <Button
            onClick={handleSubmit}
            style={{
              width: 97,
              float: 'right',
              backgroundColor: '#BC9623',
              borderRadius: 20,
              marginTop: 20,
              border: 'none',
              color: 'white',
            }}
          >
            Add
          </Button>
        </Form>
      </Drawer>
    </div>
  );
};

export default NextStepsDrawer;
