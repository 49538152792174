import React, { useState, useContext } from 'react';
import { SectionTitle } from '../Shared';
import { SummaryIcon } from 'assets/icons/DetailedProfile';
import { Typography, Row, Col } from 'antd';
import { Benchmark, Skills, Tags } from './Index';
import { SummaryDrawer } from '../Edit';
import { EditContext } from '../Context/EditContext';
import SkillsDrawer from '../Edit/SkillsDrawer';
import TagsDrawer from '../Edit/TagsDrawer';

interface PropsType {
  candidate: any;
  isBenchmark: boolean;
}

const Styles = {
  root: {
    paddingLeft: 20,
    // paddingRight: 20,
    paddingTop: 29,
    paddingBottom: 14,
    // boxShadow: '0px 0px 4px #00000040 ',
    // borderRadius: 10,
    // minHeight: '100%',
    height: '100%',
  },
  bodyContent: {
    // paddingLeft: 25,
    marginTop: 5,
    display: 'flex',
    // minHeight: '76px',
  },
};

const Summary: React.FC<PropsType> = ({ candidate, isBenchmark }) => {
  const [open, setOpen] = useState(false);
  const [openSkill, setOpenSkills] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [openTag, setOpenTags] = useState(false);
  const editContext = useContext(EditContext);
  const { setData, setUpdatedData, edit }: any = editContext;

  const handleEdit = () => {
    setOpen(true);
  };

  const handleAddSkills = () => {
    setOpenSkills(true);
  };

  const handleAddTags = () => {
    setOpenTags(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSkills = () => {
    setOpenSkills(false);
  };

  const handleCloseTags = () => {
    setOpenTags(false);
  };

  const handleUpdate = (data: any) => {
    setData({ ...editContext?.candidate, description: data });
    setUpdatedData({ ...editContext?.updatedData, description: data });
    setOpen(false);
  };

  const handleUpdateSkills = (data: any) => {
    setOpenSkills(false);
  };

  const handleUpdateTags = (data: any) => {
    setOpenTags(false);
  };
  const github = candidate?.github == '' ? null : candidate?.github;
  const facebook = candidate?.facebook == '' ? null : candidate?.facebook;
  const linkedIn = candidate?.linkedIn == '' ? null : candidate?.linkedIn;
  const twitter = candidate?.twitter == '' ? null : candidate?.twitter;
  return (
    <>
      <SkillsDrawer
        onUpdate={handleUpdateSkills}
        open={openSkill}
        handleClose={handleCloseSkills}
        candidate={candidate}
      />
      <TagsDrawer
        onUpdate={handleUpdateTags}
        open={openTag}
        handleClose={handleCloseTags}
        candidate={candidate}
      />
      <SummaryDrawer
        onUpdate={handleUpdate}
        candidate={candidate}
        open={open}
        handleClose={handleClose}
      />
      <div style={{ ...Styles.root, flexDirection: 'column' }}>
        {(candidate?.description || editContext?.edit) && (
          <Row gutter={22}>
            <Col span={23}>
              <>
                <SectionTitle
                  actionHandler={handleEdit}
                  actionType={'edit'}
                  title={'Summary'}
                  fontSize={16}
                  fontWeight={600}
                />
                {editContext?.candidate?.description != null ? (
                  <div
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#000000',
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginLeft: '6px',
                    }}
                  >
                    {editContext?.candidate?.description != null && (
                      <Typography style={{ fontWeight: 400, fontSize: '14px' }}>
                        {/* {seeMore
                        ? editContext?.candidate?.description
                        : editContext?.candidate?.description.slice(0, 400)}
                      {!seeMore &&
                        editContext?.candidate?.description.length > 300 &&
                        ' ...'}{' '} */}
                        {editContext?.candidate?.description}
                      </Typography>
                    )}
                    {/* {editContext?.candidate?.description.length > 300 && (
                    <span
                      onClick={() => setSeeMore(!seeMore)}
                      style={{
                        float: 'left',
                        marginLeft: 1,
                        cursor: 'pointer',
                        color: '#344976',
                      }}
                    >
                      {' '}
                      {seeMore ? 'See less' : 'See more'}
                    </span>
                  )} */}
                  </div>
                ) : (
                  <Typography style={{ color: 'gray' }}>
                    No summary provided by the candidate
                  </Typography>
                )}
              </>
            </Col>
          </Row>
        )}
        <Row style={{ marginTop: '1em' }} gutter={24}>
          {(candidate?.skills?.length > 0 || editContext?.edit) && (
            <Col span={15}>
              <Skills
                onAdd={handleAddSkills}
                inDrawer={false}
                candidate={candidate}
              />
            </Col>
          )}
          <Col span={9} className="p-n">
            <Tags onAdd={handleAddTags} candidate={candidate} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Summary;
