import React, { useContext } from 'react';
import { Upload } from 'antd';
import { UploadIcon, ViewIcon, DeleteIcon } from 'assets/icons/DetailedProfile';
import { EditContext } from './Context/EditContext';
import './styles.scss';
interface PropsTypes {
  fileLink?: string;
  updateAction?: any;
  title?: any;
  candidateId?: string;
}

const Styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  btn: {
    cursor: 'pointer',
    marginLeft: 5,
  },
};

const UVDButton: React.FC<PropsTypes> = ({
  fileLink,
  updateAction,
  title,
  candidateId,
}) => {
  const editContext = useContext(EditContext);
  const { setData, setUpdatedData }: any = editContext;

  const viewResume = () => {
    window.open(
      fileLink?.includes('.pdf')
        ? `${fileLink}#toolbar=0&scrollbar=0&view=fitH&view=fitV`
        : `https://docs.google.com/gview?url=${fileLink}&embedded=true`
      // `https://docs.google.com/gview?url=${fileLink}&embedded=true`,
      // '_blank'
    );
  };
  const deleteResume = () => {
    if (title == 'Attachment') {
      setData({
        ...editContext?.candidate,
        coverLetter: null,
      });
      setUpdatedData({
        ...editContext?.updatedData,
        coverLetter: null,
      });
    } else if (title == 'Resume') {
      setData({
        ...editContext?.candidate,
        resume: null,
      });
      setUpdatedData({
        ...editContext?.updatedData,
        resume: null,
      });
    }
  };

  return (
    <div style={Styles.root}>
      {editContext?.edit && (
        <div className="uploader-wrapper">
          <Upload
            style={{ width: '100%', height: 95, borderRadius: 10 }}
            action={`${process.env.REACT_APP_BASE_REST_URL}/upload/candidates/${candidateId}`}
            // action="https://api.cloudinary.com/v1_1/blonk-group/raw/upload"
            onChange={updateAction}
            listType="text"
            data={{
              upload_preset: 'cqdbrmkw',
            }}
            accept=".docx,.pdf"
            multiple={false}
          >
            <img style={Styles.btn} src={UploadIcon} />
          </Upload>
        </div>
      )}
      {fileLink && (
        <img onClick={viewResume} style={Styles.btn} src={ViewIcon} />
      )}
      {editContext?.edit && fileLink && (
        <img onClick={deleteResume} style={Styles.btn} src={DeleteIcon} />
      )}
    </div>
  );
};

export default UVDButton;
