import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Row, Col, message } from 'antd';
import {
  useGetCandidateQuery,
  useGetMatchQuery,
  useGetSingleJobQuery,
  useUpdateCandidateMutation,
  useCandidateNextStepsMutation,
} from '../../../../graphql/generated/graphql';

import './ViewCandidate.scss';
import {
  Attachment,
  Education,
  Experience,
  Languages,
  PersonalDetails,
  PersonalInfo,
  ProfessionalIntrests,
  Resume,
  TargetLocations,
  YearlyCompensation,
} from '../Profile';
import { EditContext } from '../Profile/Context/EditContext';
import { Summary } from '../Profile/Summary/Index';
import { PageHeader, TopNavigation } from '../Profile/Shared';
import Comments from '../Profile/RecruiterComments/Comments';
import NextSteps from '../Profile/RecruiterComments/NextSteps';
import Myobjective from '../Profile/Motivations/Myobjective';
import Mytarget from '../Profile/Motivations/Mytarget';
import Inspirecompanies from '../Profile/Motivations/Inspirecompanies';
import AssessmentTable from '../Profile/RecruiterComments/AssessmentTable';

const ViewCandidate: React.FC = () => {
  const { matchId, jobId } = useParams<{ matchId: string; jobId: string }>();
  const [candidates, setCandidates] = useState<any>(null);
  const location: any = useLocation();
  const history = useHistory();

  if (location.pathname.includes('undefined')) {
    history.push('/backoffice/jobs');
  }
  const [
    updateCandidateMutation,
    { data: mdata, loading: mloading, error: merror },
  ] = useUpdateCandidateMutation();
  const { data, loading, error, refetch }: any = useGetMatchQuery({
    variables: {
      matchId: matchId,
    },
    fetchPolicy: 'network-only',
  });
  const [
    candidateNextSteps,
    { data: NextStep, loading: NextStepLoading, error: NextStepErr },
  ] = useCandidateNextStepsMutation();
  const { data: jobData, loading: loadingJob }: any = useGetSingleJobQuery({
    variables: {
      jobId: jobId,
    },
    fetchPolicy: 'network-only',
  });

  const [candidateData, setCandidateData] = useState<any | null | undefined>(
    null
  );
  const [matchData, setMatchData] = useState<any | null | undefined>(null);
  const [match, setMatch] = useState<any | null | undefined>(null);
  const editContext: any = useContext(EditContext);
  // const {} = useContext(EditContext);
  // const  { setMatch:setMatchContext} = editContext
  const {
    setCandidateMatch,
    setData,
    candidate,
    updatedData,
    cancelEditProfile,
    setActivityDatas,
    setMatch: setMatchContext,
  }: any = editContext;
  useEffect(() => {
    if (data && data?.getMatch && data?.getMatch?.candidateListing) {
      const { candidateListing, ...rest }: any = data?.getMatch;
      setCandidateMatch(rest);
      setMatchData(rest);
      setData(candidateListing);
      setCandidates(location.state?.candidateArray);
    }
  }, [data]);

  useEffect(() => {
    if (editContext?.candidate) setCandidateData(editContext?.candidate);
  }, [editContext?.candidate]);
  useEffect(() => {
    const obj = {
      candidateArray: location?.state?.candidateArray,
      candidateId: location?.state?.candidateId,
      fromURL: location?.pathname,
      previousLocation: location?.state?.fromURL,
    };
    setActivityDatas(obj);
  }, []);

  useEffect(() => {
    if (editContext?.match) {
      setMatch(editContext?.match);
    }
  }, [editContext?.match]);
  const UpdateChanges = () => {
    const key = 'updatable';
    message.loading({ content: 'Updating profile...', key });
    let updatedObj;
    if (updatedData.currentLocation) {
      const {
        currentLocation: { city, country },
        ...rest
      } = updatedData;

      updatedObj = {
        city,
        country,
        ...rest,
      };
    } else {
      updatedObj = updatedData;
    }
    updateCandidateMutation({
      variables: { candidateId: candidate._id, ...updatedObj },
    })
      .then((res) => {
        message.success({
          content: 'Profile updated successfuly!',
          key,
          duration: 5,
        });
        refetch();
        cancelEditProfile();
      })
      .catch((err) => {
        message.error({ content: 'Something went wrong!', key, duration: 5 });
      });
    candidateNextSteps({
      variables: {
        matchId: match?._id,
        nextSteps: match?.nextSteps,
      },
    }).then(({ data }) => {
      console.log(data);
    });
  };

  const Motivation =
    (candidateData?.targetLocations?.length == 0 &&
      !candidateData?.personalInformation &&
      !candidateData?.yearlyCompensation?.currency &&
      candidateData?.inspiringCompanies?.length == 0 &&
      candidateData?.professionalInterests?.length == 0 &&
      candidateData?.objectives?.length == 0 &&
      candidateData?.sectors?.length == 0 &&
      !editContext?.edit) == false;

  const Background =
    (candidateData?.experiences?.length == 0 &&
      candidateData?.education?.length == 0 &&
      candidateData?.languages?.length == 0 &&
      !candidateData?.resume &&
      !candidateData?.coverLetter &&
      !editContext?.edit) == false;

  return (
    <>
      <PageHeader
        initialData={data?.getMatch?.candidateListing}
        matchData={data?.getMatch}
        refetch={refetch}
        handleSave={UpdateChanges}
      />
      <div style={{ padding: 30, paddingTop: 4 }}>
        {/* Back, Edit , Save Cancel Button */}
        {/* Main Body Content */}
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: 10,
            paddingLeft: 35,
            paddingRight: 35,
            paddingTop: 29,
            paddingBottom: 29,
          }}
        >
          {
            <>
              <div style={{ marginTop: 0, marginBottom: 30 }}>
                <TopNavigation jobId={jobId} />
              </div>
              <Row gutter={22} className="headerSection">
                <Col span={22}>
                  <PersonalInfo
                    // match={matchData}
                    match={data?.getMatch || matchData}
                    showActions={true}
                    candidate={candidateData}
                    showBenchmark={true}
                    showActivation={true}
                    loading={loading}
                  />
                  {/* <Summary candidate={candidateData} isBenchmark /> */}
                </Col>
                {/* <Col span={17}></Col> */}
              </Row>
              <Row
                style={{ marginTop: 70 }}
                gutter={22}
                className={
                  Motivation == false || Background == false
                    ? 'align_Content'
                    : ''
                }
              >
                {/* Motivation */}
                {(candidateData?.targetLocations?.length > 0 ||
                  candidateData?.professionalInterests?.length > 0 ||
                  candidateData?.objectives?.length > 0 ||
                  candidateData?.sectors?.length > 0 ||
                  candidateData?.inspiringCompanies?.length > 0 ||
                  (candidateData?.yearlyCompensation?.currency &&
                    (candidateData?.yearlyCompensation?.current?.total
                      ?.length ||
                      candidateData?.yearlyCompensation?.expected?.total
                        ?.length)) ||
                  candidateData?.personalInformation ||
                  editContext?.edit) && (
                  <Col span={Background == false ? 10 : 7}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 10,
                        fontSize: 20,
                        fontWeight: 500,
                        color: '#FFFFFF',
                        backgroundColor: '#2E4A79',
                        borderRadius: 10,
                        marginBottom: 30,
                      }}
                    >
                      <span>MOTIVATIONS</span>
                    </div>
                    <TargetLocations candidate={candidateData} />

                    <div style={{ marginTop: 20 }}>
                      <ProfessionalIntrests candidate={candidateData} />
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <Myobjective candidate={candidateData} />
                    </div>
                    <div
                      // ref={professionalInterestsRef}
                      style={{ marginTop: 20 }}
                    >
                      <Mytarget candidate={candidateData} />
                    </div>
                    <div
                      // ref={professionalInterestsRef}
                      style={{ marginTop: 20 }}
                    >
                      <Inspirecompanies candidate={candidateData} />
                    </div>

                    <div style={{ marginTop: 20 }}>
                      <YearlyCompensation
                        candidate={candidateData}
                        match={matchData}
                        showSwitch={true}
                      />
                    </div>

                    <div style={{ marginTop: 20 }}>
                      <PersonalDetails candidate={candidateData} />
                    </div>
                  </Col>
                )}

                {/* Motivation */}

                {/* Background */}
                {/* Background */}
                {(candidateData?.experiences?.length > 0 ||
                  candidateData?.education?.length > 0 ||
                  candidateData?.languages?.length > 0 ||
                  candidateData?.resume ||
                  candidateData?.coverLetter ||
                  editContext?.edit) && (
                  <Col span={Motivation == false ? 10 : 9}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 10,
                        fontSize: 20,
                        fontWeight: 500,
                        color: '#FFFFFF',
                        backgroundColor: '#BC9623',
                        borderRadius: 10,
                        marginBottom: 30,
                      }}
                    >
                      <span>BACKGROUND</span>
                    </div>
                    {/* Experience */}
                    <div style={{ marginTop: 20 }}>
                      <Experience candidate={candidateData} />
                    </div>
                    {/* Education */}
                    <div style={{ marginTop: 20 }}>
                      <Education candidate={candidateData} />
                    </div>
                    {/* Languages */}
                    <div style={{ marginTop: 20 }}>
                      <Languages candidate={candidateData} />
                    </div>
                    {/* Resume */}
                    <div style={{ marginTop: 20 }}>
                      <Resume candidate={candidateData} />
                    </div>
                    {/* Attachment */}
                    <div style={{ marginTop: 20 }}>
                      <Attachment candidate={candidateData} />
                    </div>
                  </Col>
                )}

                {/* Background End */}

                {/* Recruiter's Comment */}
                <Col span={Motivation == false || Background == false ? 10 : 8}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 10,
                      fontSize: 20,
                      fontWeight: 500,
                      color: '#FFFFFF',
                      backgroundColor: '#418394',
                      borderRadius: 10,
                      marginBottom: 30,
                    }}
                  >
                    <span>RECRUITERS’ COMMENTS</span>
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <Comments match={match} candidate={candidateData} />
                  </div>
                  {!loading && !loadingJob && (
                    <div style={{ marginTop: 20 }}>
                      <AssessmentTable match={matchData} jobData={jobData} />
                    </div>
                  )}
                  <div style={{ marginTop: 20 }}>
                    <NextSteps
                      candidate={candidateData}
                      matchNextSteps={matchData}
                    />
                  </div>
                </Col>
                {/* Recruiter's Comment End */}
              </Row>
            </>
          }
        </div>
      </div>
    </>
  );
};

export default ViewCandidate;
