import React from 'react';
import EditButton from '../EditIcon';
import DeleteButton from '../DeleteIcon';

interface PropsType {
  editHandler: () => void;
  deleteHandler: any;
}

const EditDelete: React.FC<PropsType> = ({ editHandler, deleteHandler }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <EditButton handler={() => editHandler()} />
      <div style={{ marginLeft: 5, marginRight: 5 }}></div>
      <DeleteButton handler={deleteHandler} />
    </div>
  );
};

export default EditDelete;
