import React from 'react';
import { DeleteIcon } from 'assets/icons/DetailedProfile';

interface PropsTypes {
  handler: any;
}

const EditButton: React.FC<PropsTypes> = ({ handler }) => {
  return (
    <>
      <img onClick={handler} style={{ cursor: 'pointer' }} src={DeleteIcon} />
    </>
  );
};

export default EditButton;
