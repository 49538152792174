import React from 'react';
import { Card, Row, Col, Typography, Skeleton } from 'antd';
import './IconCard.scss';

const { Title } = Typography;

interface Props {
  icon: string;
  color: string;
  label: string;
  loading: boolean;
}
const IconCard: React.FC<Props> = (props) => {
  const iconWrapper = `icon-wrapper icon-bg-${props.color}`;
  const icon = `${props.icon} text-${props.color} font-24 `;
  return (
    <>
      <Card style={{ marginBottom: '10px' }} className="displayCards">
        <Skeleton active loading={props.loading} avatar>
          <Row gutter={24}>
            <Col className="text-center">
              <span className={iconWrapper}>
                <i className={icon}></i>
              </span>
            </Col>
            <Col className="align-self-center">
              <Title level={5} className="mt-0 mb-1 text-primary">
                {props.label}
              </Title>
              <p className="mb-0 font-12 text-muted text-uppercase font-weight-semibold-alt">
                {props.children}
              </p>
            </Col>
          </Row>
        </Skeleton>
      </Card>
    </>
  );
};

export default IconCard;
